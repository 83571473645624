import React from 'react'
import { Box } from 'theme-ui';
import { InputIconProps } from './types';

function getPosition(isEndIcon?: boolean) {
  return (
    isEndIcon? { 
      right: '0px'
    } : {
      left: '0px'
    }
  )
}

export const InputIcon: React.FC<InputIconProps> = ({ sx, isEndIcon, ...props}) => {
  return (
    <Box 
      sx={{ 
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        position: 'absolute',
        top: 0,
        ...getPosition(isEndIcon),

        ...sx 
      }} 
      {...props} 
    />
  )
}