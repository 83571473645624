export default {
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    mt: 0,
    mb: 0,
  },
  display: {
    fontWeight: "display",
    lineHeight: "heading"
  },
  hint: {
    fontSize: [1],
    color: 'textMuted'
  }
}