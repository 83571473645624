import { merge, Theme } from "theme-ui";
import { ButtonTheme } from "./types";

const buttonTheme: ButtonTheme = {
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
      borderRadius: 'default',
      padding: [2],
      fontSize: [3],
      '&:hover': {
        bg: 'rgb(51, 51, 51)',
        cursor: 'pointer'
      },

      '&:disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        bg: 'rgb(51, 51, 51)'
      },
    },
    secondary: {
      color: 'white',
      borderRadius: 'default',
      bg: 'secondary',
      '&:hover': {
        opacity: 0.7,
        cursor: 'pointer'
      },
      '&:disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.3
      }
    },
    link: {
      color: 'link',
      backgroundColor: 'transparent',
      padding: 0,
      '&:hover': {
        opacity: 0.7,
        cursor: 'pointer'
      },
      '&:disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.3
      }
    }
  }
}

export const withButtonTheme = (theme: Theme) => {
  return merge(theme, buttonTheme)
}