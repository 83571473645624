import bigInt, { BigNumber } from "big-integer"
import { CryptoSymbol, Currencies } from "config/currency"
import { Account } from "state"
import Core from "./core"
import { CoinType, ICoin } from "./types"

class CryptoCurrency<S extends CryptoSymbol = any> extends Core implements ICoin {
  readonly symbol: S
  readonly type: CoinType

  protected constructor(core: Core, symbol: S) {
    super(core.sat, core.btc, core.decimals)

    this.symbol = symbol
    this.type = CoinType.token
  }

  static fromAccount(account: Account, confirmed?: boolean) {
    const sat = (account.unconfirmed && confirmed)? bigInt(account.sat).add(account.unconfirmed).toString() : account.sat
    return CryptoCurrency.fromSat(sat, account.symbol)
  }
  
  static fromSat<S extends CryptoSymbol>(sat: BigNumber | string, symbol: S) {
    const decimals = Currencies[symbol].decimals
    const core = super.fromSatInit(sat, decimals)
    return new CryptoCurrency(core, symbol)
  }

  static fromBtc<S extends CryptoSymbol>(btc: BigNumber | string, symbol: S/*, rate?: string*/) {
    const decimals = Currencies[symbol].decimals
    const core = super.fromBtcInit(btc, decimals)
    return new CryptoCurrency(core, symbol)
  }

  isToken(): boolean {
    return false
  }

  toFormatted(decimals?: number, prefix?: string) {
    return super.toFormatted(decimals || this.decimals, prefix? prefix : this.symbol + ' ')
  }

  isNegative() {
    return bigInt(this.sat).compare(bigInt.zero) === -1
  }

  mul(b: CryptoCurrency<S> | BigNumber): CryptoCurrency<S> {
    this.checkType(b)
    return new CryptoCurrency<S>(super.mul(b).round(this.decimals), this.symbol)
  }

  compare(b: CryptoCurrency<S> | BigNumber): number {
    this.checkType(b)
    return super.compare(b)
  }

  negate(): CryptoCurrency<S> {
    return new CryptoCurrency(super.negate(), this.symbol)
  }

  add(b: CryptoCurrency<S> | BigNumber, sat: boolean = false): CryptoCurrency<S> {
    this.checkType(b)
    return new CryptoCurrency<S>(super.add(b, sat), this.symbol)
  }

  copy(): CryptoCurrency<S> {
    return new CryptoCurrency(new Core(this.sat, this.btc, this.decimals), this.symbol)
  }

  private checkType(b: CryptoCurrency<S> | BigNumber) {
    if (b instanceof CryptoCurrency && this.symbol !== b.symbol) {
      throw new TypeError(`Different types: ${this.symbol} and ${b.symbol}`)
    }
  }
}

export default CryptoCurrency
