import { Box, Flex, Text } from "theme-ui"
import  { CryptoCurrency, ICoin } from "utils/coin"
import { formatAddress } from "utils/formatAddress"
import { Skeleton } from ".."
import { AccountItemProps } from "./types"

export const AccountItem: React.FC<AccountItemProps> = ({ account, onSelected, ...props }) => {
  //const MainIcon = Icons[`${account.balance.symbol}Icon`]
  const coin = CryptoCurrency.fromAccount(account)
  return (
    <Flex 
      p={3}
      mb={2}
      onClick={() => onSelected(account)} 
      sx={{ 
        backgroundColor: 'input',
        borderRadius: 'default',
        boxShadow: 'default',
        justifyContent: 'space-between',
        alignItems: 'center', 
        width: '100%',
        
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'input',
          opacity: 0.8
        }
      }}
      {...props} 
    >
      <Box>
        {formatAddress(account.address, 10)}
      </Box>
      <Flex>
        {
          !coin.isNegative()?
          <>
            <Text variant='hint'>
              {coin.btc}
            </Text>
            <Text ml={1} variant='hint'>
              {account.symbol}
            </Text>
          </>:
          <Skeleton sx={{ width: '60px' }}/>
        }
      </Flex>
        
    </Flex>
  )
}