
   
import React, { useEffect } from "react";
import { Box, Text, Heading } from "theme-ui";
import { Toast } from "./Toast";
import { ToastContainer } from "./ToastContainer";
import { AlertTypes, ToastContextProps } from "./types";

const ToastContext = React.createContext<ToastContextProps>({
  error: console.log,
  warn: console.log,
  success: console.log,
  clearAll: () => {},
  remove: () => {}
});

let toastCount = 0;

function ToastProvider({ children }: any) {
  const [toasts, setToasts] = React.useState<any[]>([]);

  const add = (type: AlertTypes, content: any, sx = {}) => {
    const id = toastCount++;

    let toast: any = { content, id, sx }

    switch (type) {
      case 'success':
        toast = { ...toast, variant: 'toasts.success', title: 'Success' };
        break
      
      case 'error':
        toast = { ...toast, variant: 'toasts.error', title: 'Error' };
        break

      case 'warning':
        toast = { ...toast, variant: 'toasts.warning', title: 'Warning' };
        break
    }

    setToasts(prev => [...prev, toast]);
  };

  const warn = (content: any) => add('warning', content)

  const error = (content: any) => add('error', content)

  const success = (content: any) => add('success', content)

  const clearAll = () => setToasts(prev => [])

  useEffect(() => {

  }, [])

  const remove = (id: number) => {
    const newToasts = toasts.filter(t => t.id !== id);
    setToasts(prev => newToasts);
  };
  // avoid creating a new fn on every render
  const onDismiss = (id: number) => () => remove(id);

  return (
    <ToastContext.Provider value={{ warn, success, error, remove, clearAll }}>
      {children}
      <ToastContainer>
        {toasts.map(({ title, content, variant, id, sx, ...rest }) => (
          <Toast variant={variant} key={id} sx={sx} onDismiss={onDismiss(id)} {...rest}>
            <Box>
              <Heading sx={{ fontWeight: '300' }} as="h4">{title}</Heading>
              <Text><small>{content}</small></Text>
            </Box>
            
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
}

export { ToastProvider, ToastContext }