import React from 'react'
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input'
import { Box, SxProp } from 'theme-ui'

export const CodeInput: React.FC<ReactCodeInputProps & SxProp> = ({
  sx,
  className,
  ...props
}) => {
  return ( 
    <Box sx={{
      '& input': {
        boxShadow: ['default'],
        //outline: 'none',
        width: '42px',
        height: '60px',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        fontSize: ['20px', '30px'],
        borderRadius: '10px',
        border: 'none',
        textAlign: 'center',
        margin: [1],
        '&:focus': {
          outline: '2px solid #9e8657',
        },
        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.4) !important',
          boxShadow: 'none',
          color: '#2c2c2c !important'
        },
        ...sx
      },
      
    }}>
      <ReactCodeInput className={className} inputStyle={{
        textTransform: 'uppercase'
      }} {...props} />
    </Box>
  )
}