import React from 'react'
import { Container, SxProp } from 'theme-ui'

export const StatusLayout: React.FC<{ children: React.ReactNode } & SxProp> = ({ sx, children }) => {
  return (
    <Container sx={{
      //background: '#f6c65d',
      //background: 'url("../../resources/images/content/section-bg-3.png"),-webkit-gradient(linear, left top, left bottom, from(#f6c65d), to(#d8a841))',
      background: 'url("/section-bg-3.png"),linear-gradient(to bottom, #f6c65d 0%, #d8a841 100%)',
      WebkitFilter: 'progid:dximagetransform.microsoft.gradient(startColorstr="#f6c65d", endColorstr="#d8a841", GradientType=0)',
      filter: 'progid:dximagetransform.microsoft.gradient(startColorstr="#f6c65d", endColorstr="#d8a841", GradientType=0)',
      backgroundPosition: 'top right',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx
    }}>
      {children}
    </Container>
  )
}