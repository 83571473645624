import { useContext } from 'react'
import { WalletContext } from './Provider'

const useWallet = () => {
  const walletContext = useContext(WalletContext)

  return walletContext
}

export default useWallet
