import React, { createContext, useRef, useState } from "react";
import { Box } from "theme-ui";
import { Overlay } from "components";
import { ModalsContext } from "./types";
import { HardwareGuardModal } from "./modals/HardwareGuardModal";

export const Context = createContext<ModalsContext>({
  isOpen: false,
  nodeId: "",
  modalNode: null,
  setModalNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  setCloseOnOverlayClick: () => true,
});

const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalNode, setModalNode] = useState<React.ReactNode>();
  const [nodeId, setNodeId] = useState("");
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);

  //const [prevModalNode, setPrevModalNode] = useState<React.ReactNode>(undefined);
  //const [prevNodeId, setPrevNodeId] = useState("");
  //const ref = useRef<React.ReactNode>()
  //ref.current = prevModalNode

  const handlePresent = (node: React.ReactNode, newNodeId: string) => {
    /*
    if (isOpen && newNodeId !== nodeId) {
      setPrevNodeId(nodeId)
      setPrevModalNode(modalNode)
    }
    */

    setModalNode(node);
    setIsOpen(true);
    setNodeId(newNodeId);
  };

  const handleDismiss = () => {
    /*
    if (ref.current !== undefined) {
      setModalNode(ref.current);
      setNodeId(prevNodeId);

      setPrevModalNode(undefined)
      setPrevNodeId("")
    } else {
    */
    setModalNode(undefined);
    setIsOpen(false);
    setNodeId("");
  };

  const handleOverlayDismiss = () => {
    if (closeOnOverlayClick) {
      handleDismiss();
    }
  };

  return (
    <Context.Provider
      value={{
        isOpen,
        nodeId,
        modalNode,
        setModalNode,
        onPresent: handlePresent,
        onDismiss: () => handleDismiss(),
        setCloseOnOverlayClick,
      }}
    >
      {isOpen && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 200
        }}>
          {<Overlay show={true} onClick={handleOverlayDismiss} />}

          {modalNode/*React.isValidElement(modalNode) &&
            React.cloneElement(modalNode, {
              onDismiss: handleDismiss,
            })*/}
        </Box>
      )}
      {children}
    </Context.Provider>
  );
};

export default ModalProvider;