import { useEffect, useState } from 'react'
import addressValidator from 'multicoin-address-validator'
import { ICoin } from 'utils/coin'
import { CurrencySymbol } from 'config/currency'

export type VallidationProps<T extends CurrencySymbol> = {
  'equal': {
    to: string,
    message?: string
  },
  'hex': {
    value: boolean,
    message?: string
  },
  'required': {
    value: boolean,
    message?: string
  },
  'custom': {
    isValid: (t: any) => boolean,
    message?: string
  },
  'pattern': {
    value: string,
    message?: string
  },
  'length': {
    m?: number,
    e?: number,
    l?: number,
    message?: string
  },
  'address': {
    valid: boolean,
    symbol: string,
    net?: 'mainnet' | 'testnet',
    message?: string
  },
  'balance': {
    me?: ICoin,
    le?: ICoin,
    m?: ICoin,
    l?: ICoin,
    message?: string
  }
}

export type ValidationtTypes = keyof VallidationProps<any>

function isHex(v: string) {
  return Boolean(v.match(/^[0-9a-f]+$/i))
}

export type UseFormProps<T extends CurrencySymbol> = {
  validations: Record<string, Partial<VallidationProps<T>>>,
  onSubmit?: (t: any) => void,
  synchronous?: boolean
  initialValues?: Record<string, any>
}

export const useForm = <T extends CurrencySymbol = any>(options: UseFormProps<T>) => {
  const [data, setData] = useState<Record<string, any>>((options?.initialValues || {}));
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [tainted, setTainted] = useState<boolean>(false)
  const handleChange = (key: string, value: any) => {
    const newData = {
      ...data,
      [key]: value,
    }
    setData(newData);
    setTainted(true)
  };

  const validate = (data: Record<string, any>, priorityKey?: string): boolean => {
    try {
      const validations = options.validations;
      
      let valid = true;
      const newErrors: Record<string, string> = {};

      let keys = Object.keys(validations)
      // check the value that is being changed first
      if (priorityKey) {
        const i = keys.findIndex(k => priorityKey === k)
        keys = [priorityKey, ...keys.slice(0, i), ...keys.slice(i + 1)]
      }

      for (const key of keys) {
        const value = data[key];
        const validation = validations[key];

        const required = validation?.required
        if (required?.value && !value) {
          valid = false;
          newErrors[key] = required?.message || 'Please, enter value';
          break;
        }

        const hex = validation?.hex
        if (hex?.value && !isHex(value.toLowerCase())) {
          valid = false
          newErrors[key] = hex.message || 'Should be hex value';
          break
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message || 'Invalid Patter';
          break;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message || 'Custom verification failed';
          break;
        }

        const length = validation?.length
        if (length?.e && value.length != length?.e) {
          valid = false
          newErrors[key] = length.message || `Enter value should be ${length?.e} characters`;
          break
        }

        if (length?.l && value.length >= length?.l) {
          valid = false
          newErrors[key] = length.message || `Enter value less than ${length?.l} characters`;
          break
        }

        if (length?.m && value.length <= length?.m) {
          valid = false
          newErrors[key] = length.message || `Enter value more than ${length?.m} characters`;
          break
        }
        
        const equal = validation?.equal
        if (equal?.to && data[equal.to] && data[equal.to] !== value) {
          valid = false
          newErrors[key] = equal.message || 'Not Equal'
          break;
        }

        const address = validation?.address
        if (address?.valid && !addressValidator.validate(value, address?.symbol, address?.net || 'mainnet')) {
          valid = false
          newErrors[key] = address?.message || `Is not valid ${address?.symbol} address`
          break;
        }

        const balance = validation?.balance
        
        let status = null
        if (balance?.m) {
          status = value.compare(balance?.m)
          if (status == 0 || status == -1) {
            valid = false
            newErrors[key] = balance?.message || balance?.m.btc === '0'? 'Not enough balance' : `Enter value more ${balance?.m.btc} ${balance?.m.symbol}`
            break;
          }
        }

        if (balance?.l) {
          status = value.compare(balance?.l)
          if (status == 0 || status == 1) {
            valid = false
            newErrors[key] = balance?.message || `Enter value less ${balance?.l.btc} ${balance?.l.symbol}`
            break;
          }
        }

        
        if (balance?.le) {
          status = value.compare(balance?.le)
          if (status == 1) {
            valid = false
            newErrors[key] = balance?.message || `Enter value less or equal ${balance?.le.btc} ${balance?.le.symbol}`
            break;
          }
        }



        if (balance?.me) {
          status = value.compare(balance?.me)
          if (status == -1) {
            valid = false
            newErrors[key] = balance?.message || `Enter value more or equal ${balance?.me.btc} ${balance?.me.symbol}`
            break;
          }
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return valid;
      }

      setErrors({})
      return valid
    }
    catch (exc) {
      console.log(exc)
      return false
    }
  }

  useEffect(() => {
    //setErrors({})
    if (tainted && !options.synchronous) {
      validate(data)
    }
  }, [JSON.stringify(data), JSON.stringify(options)])

  const handleSubmit = (e?: any) => {
    e?.preventDefault();
    const valid = validate(data)

    if (options.onSubmit && valid) {
      options.onSubmit(data);
    }
    return valid
  };

  return {
    data,
    tainted,
    handleChange,
    handleSubmit,
    errors,
    resetForm: () => setData((options?.initialValues || {}))
  };
};