import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserState } from 'state/types'

export const initialState: UserState = {

}


export const profileSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    
  },
})

export default profileSlice.reducer