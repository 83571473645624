import React, { createContext, useState } from 'react'
import { ProviderState, ApiProviderProps, IAccountsStorage } from "./types"
import { LocalStorage } from './localStorage'
import { useWallet } from 'contexts/wallet'
import { useEffect } from 'react'
import { StatusLayout } from 'components/StatusLayout'
import { Box, Flex, Heading, Spinner, Text } from 'theme-ui'

export const StorageContext = createContext<ProviderState>({
  accountsStorage: new LocalStorage()
})

export const StorageProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [accountsStorage, setAccountsStorage] = useState<IAccountsStorage | null>(null)
  
  const { provider, authenticated } = useWallet()

  useEffect(() => {
    const init = async () => {
      try {
        const acc = (await provider.getAccounts('btc', [0], false))[0]
        if (acc && acc.address) {
          setAccountsStorage(new LocalStorage(acc.address))
        }
      }
      catch (exc) {
        console.log(exc)
      }
    }

    if (authenticated) {
      init()
    }
  }, [authenticated])
  
  const renderWaiting = () => {
    return (
      <StatusLayout sx={{ justifyContent: 'center' }}>
        <Box>
          
          <Box>
            <Heading>Starting profile...</Heading>
          </Box>
        </Box>
      </StatusLayout>
    )
  }

  return (
    <StorageContext.Provider 
      //@ts-ignore
      value={{ accountsStorage }}
    >
      {!authenticated || accountsStorage? children : renderWaiting()}
    </StorageContext.Provider>
  )
}