import { EthLikeSymbol as EthLike } from "@bitfi/bitfi.js"
import { Symbol } from "@bitfi/bitfi.js/types/src/v2/types"

export type feeUnit = 'gwei' | 'sat/byte'

export type BaseCurrency = {
  decimals: number,
  multiaddress: boolean,
  feeUnit?: feeUnit
}

export type FiatSymbol = 'usd'
export type Erc20Symbol = string
export type EthLikeSymbol = EthLike
export type CryptoSymbol = 'btc' | 'eth' | 'dag' // Exclude<Symbol, 'ltc'>
export type CurrencySymbol = CryptoSymbol | FiatSymbol

export const isUxtoModel = (symbol: CryptoSymbol) => symbol === 'btc' //|| symbol === 'dgb'
export const isAccountModel = (symbol: CryptoSymbol) => symbol === 'eth'

export type Explorer = {
  getAccountUrl: (addr: string) => string
  getTxUrl: (hash: string) => string
}

export type CryptoSymbolConfig = {
  [key in CryptoSymbol]: {
    decimals: number,
    name: string,
    symbol: key,
    multiaddress: boolean,
    feeUnit: string,
    explorer: Explorer
  }
}

export type FiatSymbolConfig = {
  [key in FiatSymbol]: {
    name: string,
    symbol: key,
    decimals: number
  }
}

export const CryptoCurrencies: CryptoSymbolConfig = {
  'btc': {
    name: 'Bitcoin',
    symbol: 'btc',
    decimals: 8,
    multiaddress: true,
    feeUnit: 'sat/byte',
    explorer: {
      getAccountUrl: (addr) => `https://blockstream.info/address/${addr}`,
      getTxUrl: (hash) => `https://blockstream.info/tx/${hash}`
    }
  },
  /*
  'dgb': {
    symbol: 'dgb',
    name: 'Digybite',
    decimals: 8,
    multiaddress: false,
    feeUnit: 'sat/byte',
    explorer: {
      getAccountUrl: (addr) => `https://etherscan.io/address/${addr}`,
      getTxUrl: (hash) => `https://etherscan.io/tx/${hash}`
    }
  },
  */
  'eth': {
    symbol: 'eth',
    name: 'Ethereum',
    decimals: 18,
    multiaddress: false,
    feeUnit: 'gwei',
    explorer: {
      getAccountUrl: (addr) => `https://etherscan.io/address/${addr}`,
      getTxUrl: (hash) => `https://etherscan.io/tx/${hash}`
    }
  },
  
  'dag': {
    symbol: 'dag',
    name: 'Constellation',
    decimals: 8,
    multiaddress: false,
    feeUnit: 'sat',
    explorer: {
      getAccountUrl: (addr) => `https://mainnet.dagexplorer.io/search?term=${addr}`,
      getTxUrl: (hash) => `https://mainnet.dagexplorer.io/search?term=${hash}`
    }
  }
  
}

export const FiatCurrencies: FiatSymbolConfig = {
  'usd': {
    symbol: 'usd',
    name: 'Dollar',
    decimals: 2
  }
}

export type CurrenciesConfig = FiatSymbolConfig & CryptoSymbolConfig

export const Currencies: CurrenciesConfig = {
  ...CryptoCurrencies,
  ...FiatCurrencies
} as const
