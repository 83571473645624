import React from 'react'
import { Box, Heading } from 'theme-ui'
import { StatusLayout } from '..'

const PageLoader: React.FC = () => {
  return (
    <StatusLayout sx={{ justifyContent: 'center' }}>
      <Box>
        <Heading>Loading page...</Heading>
      </Box>
      
    </StatusLayout>
  )
}

export { PageLoader }