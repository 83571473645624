//import styled, { keyframes, DefaultTheme } from "styled-components";
import { Flex } from "theme-ui";
import { keyframes } from '@emotion/react'
import { MENU_ENTRY_HEIGHT } from "../config";
import { MenuEntryProps } from "./types";

export const MenuEntry: React.FC<MenuEntryProps> = ({ sx, secondary, isActive, ...props }) => {
  return (
    <Flex
      sx={{
        cursor: 'pointer',
        alignItems: 'center',
        height: `${MENU_ENTRY_HEIGHT}px`,

        paddingLeft: [secondary ? 4 : '12px'],
        fontSize: [secondary? 1 : 2],
        //backgroundColor: secondary ? 'white' : "transparent",
        color: 'menu.text',
        boxShadow: isActive ? `menu.selectedItem` : "none",
        
        // Safari fix
        flexShrink: 0,
        
        '& a': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          color: 'menu.text',
          textDecoration: 'none'
        },
        
        '& svg': {
          fill: 'menu.text'
        },
        
      
        '&:hover': {
          backgroundColor: 'menu.backgroundSecondary',

          '& *': {
            color: 'menu.text',
          },
          
          '& svg': {
            fill: 'menu.text'
          },
        },

        ...sx
      }}
      {...props}
    />
  )
}

MenuEntry.defaultProps = {
  secondary: false,
  isActive: false,
  role: "button",
};

