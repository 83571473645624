import { useContext } from 'react'
import { ProviderState } from './types'
import { StorageContext } from './Provider'

const useStorage = (): ProviderState => {
  const context = useContext(StorageContext)
  return context
}

export default useStorage
