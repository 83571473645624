import { ThemeUIStyleObject } from "theme-ui";

export default {
  
  root: {
    fontFamily: "Inter, sans-serif",
    lineHeight: "body",
    fontWeight: "body",
    backgroundColor: "#F8F9FB"
  },
  
  
  "a": {
    "color": "primary",
    "textDecoration": "none",
    ":hover": {
      "textDecoration": "underline"
    }
  },
  "p": {
    "mb": 3,
    "lineHeight": "body"
  },
  "h1": {
    "fontFamily": "heading",
    "fontWeight": "heading",
    "lineHeight": "heading",
    "mt": 0,
    "mb": 2,
    "fontSize": 7
  },
  "h2": {
    "fontFamily": "heading",
    "fontWeight": "heading",
    "lineHeight": "heading",
    "mt": 0,
    "mb": 2,
    "fontSize": 6
  },
  "h3": {
    "fontFamily": "heading",
    "fontWeight": "heading",
    "lineHeight": "heading",
    "mt": 0,
    "mb": 2,
    "fontSize": 5
  },
  "h4": {
    "fontFamily": "heading",
    "fontWeight": "heading",
    "lineHeight": "heading",
    "mt": 0,
    "mb": 2,
    "fontSize": 4
  },
  "h5": {
    "fontFamily": "heading",
    "fontWeight": "heading",
    "lineHeight": "heading",
    "mt": 0,
    "mb": 2,
    "fontSize": 3
  },
  "h6": {
    "fontFamily": "heading",
    "fontWeight": "heading",
    "lineHeight": "heading",
    "mt": 0,
    "mb": 2,
    "fontSize": 2
  },
  "blockquote": {
    "fontSize": 3,
    "mb": 3
  },
  "table": {
    "width": "100%",
    "marginBottom": 3,
    "color": "gray.9",
    "borderCollapse": "collapse"
  },
  "th": {
    "verticalAlign": "bottom",
    "borderTopWidth": 2,
    "borderTopStyle": "solid",
    "borderTopColor": "gray.3",
    "borderBottomWidth": 2,
    "borderBottomStyle": "solid",
    "borderBottomColor": "gray.3",
    "padding": ".75rem",
    "textAlign": "inherit"
  },
  "td": {
    "borderBottomWidth": 2,
    "borderBottomStyle": "solid",
    "borderBottomColor": "gray.3",
    "verticalAlign": "top",
    "padding": ".75rem"
  },
  "inlineCode": {
    "color": "pink"
  },
  "img": {
    "maxWidth": "100%",
    "height": "auto"
  },
  hr: {
    color: '#E8E8E8',
  }
} as Record<string, ThemeUIStyleObject>