import { useState } from 'react'
import { CopyIcon, SvgProps } from '..'
import { copyText } from 'utils'
import { CopyButtonProps } from './types'
import { Box } from 'theme-ui'
import { useToast } from 'widgets/Toast'


const Tooltip: React.FC<{
  isTooltipDisplayed: boolean
  tooltipTop: number
  tooltipRight: number
  tooltipFontSize?: number
}> = ({ 
  isTooltipDisplayed,
  tooltipRight,
  tooltipTop,
  tooltipFontSize,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: isTooltipDisplayed? 'inline' : 'none',
        position: 'absolute',
        padding: '8px',
        top: `${tooltipTop}px`,
        right: `${tooltipRight}px`,
        textAlign: 'center',
        fontSize: `${tooltipFontSize}px` ?? '100%',
        backgroundColor: 'secondary',
        color: 'black',
        borderRadius: ['default'],
        OPACITY: '0.7',
        width: 'max-content'
      }} 
      {...props}
    >
      {children}
    </Box>
  )
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  tooltipMessage,
  width = '30px',
  tooltipTop,
  tooltipRight,
  tooltipFontSize,
  buttonColor = 'primary',
  ...props
}) => {
  const { success } = useToast()
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  const displayTooltip = () => {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
  }

  return (
    <Box style={{
      position: 'relative',
      width
    }}>
      <CopyIcon
        style={{ cursor: 'pointer' }}
        color={buttonColor}
        onClick={() => copyText(text, () => success(tooltipMessage))}
        {...props}
      />
      {
        /*
        <Tooltip
          isTooltipDisplayed={isTooltipDisplayed}
          tooltipTop={tooltipTop}
          tooltipRight={tooltipRight || 0}
          tooltipFontSize={tooltipFontSize}
        >
          {tooltipMessage}
        </Tooltip>
        */
      }
    </Box>
  )
}
