import { BoxProps } from "theme-ui";

export const animation = {
  WAVES: "waves",
  PULSE: "pulse",
} as const;

export const variant = {
  RECT: "rect",
  CIRCLE: "circle",
} as const;

export type Animation = typeof animation[keyof typeof animation];
export type SkeletonVariant = typeof variant[keyof typeof variant];

export interface SkeletonProps extends BoxProps {
  animation?: Animation;
  text?: string
  variant?: SkeletonVariant;
}
