import bigInt, { BigNumber } from "big-integer";
import format from 'format-number'
import { fromSatoshi, toSatoshi } from "./satoshi";
import { ICoin } from "./types";

function getDecimals(rate: string): number {
  const i = rate.toString().indexOf('.')
  if (i === -1)
    return 0
  
  return rate.length - i - 1
}

class Core {
  readonly sat: string;
  readonly btc: string;
  readonly decimals: number

  protected constructor(sat: string, btc: string, decimals: number) {
    this.sat = sat
    this.btc = btc
    this.decimals = decimals
  }

  isNegative() {
    return bigInt(this.sat).compare(bigInt.zero) !== -1
  }

  mul(b: Core | BigNumber): Core {
    if (!(b instanceof Core)) {
      let decimals = 0
      if ((typeof b === 'string' || typeof b === 'number') && parseFloat(b.toString()) % 1 != 0) {
        decimals = getDecimals(b.toString())
      }
      return this.mulSat(b, decimals)
    }
    this.checkDecimals(b)
    return this.mulSat(b.sat)
  }

  protected static fromSatInit(sat: BigNumber | string, decimals: number) {
    if (typeof sat === 'string') {
      sat = BigInt(sat)
    }
    const btc = fromSatoshi(sat, decimals)
    return new Core(sat.toString(), btc, decimals)
  }

  protected static fromBtcInit(btc: BigNumber | string, decimals: number) {
    btc = btc.toString()
    const sat = toSatoshi(btc, decimals).toString()
    
    if (btc.indexOf('.') !== btc.length - 1) {
      btc = Core.fromSatInit(sat, decimals).btc
    }
    
    return new Core(sat, btc, decimals)
  }

  toFormatted(decimals: number, prefix?: string) {
    return format({ 
      prefix, 
      truncate: decimals, 
      padRight: decimals 
      //@ts-ignore
    })(this.btc)
  }

  compare(b: Core | BigNumber | string): number {
    if (!(b instanceof Core)) {
      return this.compare(Core.fromBtcInit(b, this.decimals))
    }

    this.checkDecimals(b)
    return bigInt(this.sat).compare(bigInt(b.sat.toString()))
  }

  negate(): Core {
    const sat = bigInt(this.sat).negate()
    return Core.fromSatInit(sat, this.decimals)
  }

  add(b: Core | BigNumber | string, sat: boolean = false): Core {
    if (!(b instanceof Core)) {
      const coin = sat? Core.fromSatInit(b, this.decimals) : Core.fromBtcInit(b, this.decimals)
      return this.add(coin)
    }

    this.checkDecimals(b)
    const satoshi = bigInt(this.sat.toString()).add(b.sat)
    return Core.fromSatInit(satoshi, this.decimals)
  }

  private checkDecimals(b: Core) {
    if (this.decimals !== b.decimals) {
      throw new TypeError(`Decimals doesn't much: ${this.decimals} and ${b.decimals}`)
    }
  }

  convertTo(rate: Core): Core {
    const decimalsTo = rate.decimals
    return this.mul(rate).round(decimalsTo)
  }

  copy(): Core {
    return new Core(this.sat, this.btc, this.decimals)
  }

  round(decimals: number): Core {
    if (decimals >= this.decimals) {
      return this.copy()
    }

    return Core.fromSatInit(toSatoshi(this.btc, decimals), decimals)
  }

  to(rate: ICoin): ICoin {
    const decimalsTo = rate.decimals
    const coreRate = new Core(rate.sat, rate.btc, rate.decimals)
    const core = this.mul(coreRate.btc).round(decimalsTo)
    const copy = rate.copy()

    //@ts-ignore
    copy.sat = core.sat
    //@ts-ignore
    copy.btc = core.btc
    //@ts-ignore
    copy.decimals = core.decimals
    return copy
  }

  protected mulSat(b: BigNumber, decimals: number = 0): Core {
    const factor = toSatoshi(b.toString(), decimals)

    const newDecimals = this.decimals + decimals
    const btc = fromSatoshi(bigInt(this.sat.toString()).multiply(factor), newDecimals)
    return Core.fromBtcInit(btc, newDecimals)
  }
}

export default Core