import { CryptoSymbol, FiatSymbol } from "config/currency"
import useCryptoDataProvider from "contexts/cryptodata/useCryptoDataProvider"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from ".."
import { fetchRates } from "./reducer"
import { UseRateResponse } from "./types"

export const useRate = (): UseRateResponse => {
  const dispatch = useAppDispatch()
  const api = useCryptoDataProvider()
  const rateState = useSelector<AppState, AppState['rate']>((state) => state.rate)

  const update = (symbols: CryptoSymbol[], fiats: FiatSymbol[]) => 
    dispatch(fetchRates({ symbols, fiats, api }))

  return { ...rateState, update }
}