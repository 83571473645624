import { ChargeLevel } from 'contexts/wallet/types'
import React from 'react'
import { Box, Progress, ProgressProps } from 'theme-ui'
import { BatteryIcon, BoltIcon, EmptyBatteryIcon, NotCharging } from '..'

const getColor = (level: number) => {

  if (level > 70)
    return '#97e162'
  
  if (level > 30)
    return '#dde56a'

  return '#cc6d4a'
}

export const Battery: React.FC<ChargeLevel & ProgressProps & { inverse?: boolean }> = ({ inverse, level, isCharging, ...props }) => {
  
  return (
    <Box mr={1} sx={{ position: 'relative', cursor: 'default' }}>
      
      <Box  sx={{ position: 'relative', '& *:hover ': { opacity: 1 } }}>
        <BatteryIcon percantage={level}/>
        {
          /*
          <EmptyBatteryIcon color='gray.8' sx={{ position: 'absolute',   bottom: -21,
            right: -20 }} px={0} size={'60px'} width="60px" height='30px'/>
        <Progress 
          color={getColor(level)} 
          sx={{
            
           
            borderRadius: '0px',
            bg: 'transparent', 
            width: '25px', 
            height: '14px',
            ...props.sx
          }} 
          max={1} 
          value={level / 100}
          {...props}
        />
          */
        }
        
      </Box>
      {
          <Box sx={{ 
            position: 'absolute', 
            top: '20px',
            paddingTop: '2px', 
            right: 0,
            textAlign: 'center',
            fontSize: '11px',
            color: 'black', 
            left: 'calc(50% - 11px)',
            fontWeight: '1500'
          }}>{level}%</Box>
      }
      {
        
        <Box sx={{ 
          position: 'absolute', 
          top: '0px',
          fontSize: '11px',
          right: '33px',
          fontWeight: '1500'
        }}>
          {
            isCharging? 
              <BoltIcon color='primary' width='20px'/> :
              <NotCharging color='primary' width='20px'/>
          }
        </Box>
      }
      
    </Box>
  )
}