import { merge, Theme } from "theme-ui"
import { CardTheme, CardVariant } from './types'

const cardTheme: CardTheme = {
  cards: {
    primary: {
      backgroundColor: 'white',
      borderRadius: ['default'],
      borderColor: '#e2e2e2dd',
      boxShadow: 'default',
  
      '&[aria-disabled="true"]': {
        backgroundColor: 'red',
      },
    }, 
    info: {
      padding: 1,
      backgroundColor: 'white',
      borderRadius: ['default'],
      
      borderColor: 'muted',
      boxShadow: 'default',
      width: '300px',
      border: '1px solid white',
      wordBreak: 'break-word',
      
      '&:hover': {
        cursor: 'pointer',
        boxShadow: 'sm',
        '&:hover': {
          borderColor: '#ebebeb'
        },
      }
    }
  }
}

export const withCardTheme = (theme: Theme) => {
  return merge(theme, cardTheme)
}