import axios from "axios"
import { CryptoSymbol, EthLikeSymbol, FiatSymbol } from "config/currency"
import { RateState } from "state/types"
import { CryptoCurrency, ICoin } from "utils/coin"
import { ERCToken, EthplorerResponse, ICryptoData, Tx } from "./types"
import { DagNetwork, TransactionV2 } from "@stardust-collective/dag4-network"
import {dagDi} from "@stardust-collective/dag4-core";
import { Utxo } from "utils/fee/estimateBtc"
import { isSegwit } from "utils/isSegwit"
import { FeeOptions } from "widgets/Modal/modals/SendModal/FeeInput"
import { Token } from "typescript"
import { response } from "./tokensmock"
import { InsightNode } from "./insight"

const BALANCER = process.env.REACT_APP_BALANCER

function fetcher(url: string, options: any) {
  return window.fetch.bind(window)(url, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'text/plain'
    }
  });
}
const fetch = fetcher.bind(window)


dagDi.useFetchHttpClient(fetch);

const dagNetwork = new DagNetwork()
dagNetwork.config({
  id: 'ceres',
  networkVersion: '2.0',
  beUrl: 'https://be-mainnet.constellationnetwork.io',
  l0Url: 'https://l0-lb-mainnet.constellationnetwork.io',
  l1Url: 'https://l1-lb-mainnet.constellationnetwork.io'
  /*
  beUrl: process.env.NODE_ENV === 'production'? 
    `${BALANCER}/dag/be` : 'https://block-explorer.constellationnetwork.io',
  lbUrl: process.env.NODE_ENV === 'production'? 
    `${BALANCER}/dag/lb` : 'http://lb.constellationnetwork.io:9000'
  */
})

const ETHERSCAN_URL = process.env.NODE_ENV === 'production'? 
  `${BALANCER}/eth/etherscan` :
  `https://api.etherscan.io/api?apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`

const INFURA_URL = process.env.NODE_ENV === 'production'? 
  `${BALANCER}/eth/infura` :
  `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`

export class Balancer implements ICryptoData {
  constructor() {}

  public async getUtxo(address: string, symbol: CryptoSymbol): Promise<{ confirmed: string, unconfirmed: string, utxo: Utxo[] }> {
    /*
    if (symbol == 'dgb') { 
      const node = new InsightNode(symbol)
      return node.getBalance(address)
    } 
    */

    const url = `https://api.blockcypher.com/v1/btc/main/addrs/${address}?unspentOnly=true`

    const { data } = await axios.get(url)
    
    const utxo = [...(data.unconfirmed_txrefs || []), ...(data.txrefs || [])].map((ref: any) => ({
      hash: ref.tx_hash, 
      value: ref.value, 
      isSegwit: isSegwit(address),
      tx_output_n: ref.tx_output_n
    } as Utxo))

    return {
      confirmed: data.balance,
      unconfirmed: data.unconfirmed_balance,
      utxo
    }
  }

  public async getTxRef(address: string, symbol: CryptoSymbol) {
    console.log('MAKING LAST REF REQUEST')
    const ref = await dagNetwork.l1Api.getAddressLastAcceptedTransactionRef(address)
    console.log(ref)
    return {
      ...ref,
      prevHash: ref.hash
    }
  }
  
  public async getNonce(address: string, symbol: EthLikeSymbol) {
    const url = `${ETHERSCAN_URL}&module=proxy&action=eth_getTransactionCount&address=${address}&tag=latest`
    
    const { data } = await axios.get(url)

    if (!data.result) {
      throw new Error('Unable to fetch none')
    }

    return parseInt(data.result, 16).toString()
  }

  public async getFees(symbol: CryptoSymbol): Promise<FeeOptions> {
    if (symbol === 'eth') {
      const { data } = await axios.get(`${ETHERSCAN_URL}&module=gastracker&action=gasoracle`)

      if (data.message !== 'OK') {
        throw new Error('Unable to fetch balance')
      }

      return {
        'low': CryptoCurrency.fromSat(data.result.SafeGasPrice, 'eth').mul(Math.pow(10, 9)),
        'medium': CryptoCurrency.fromSat(data.result.ProposeGasPrice, 'eth').mul(Math.pow(10, 9)),
        'high': CryptoCurrency.fromSat(data.result.FastGasPrice, 'eth').mul(Math.pow(10, 9)),
      }
    } if (symbol === 'btc') {
      

      const { data } = await axios.get(`https://api.blockcypher.com/v1/btc/main`)

      return {
        'low': CryptoCurrency.fromSat(Math.round(data.low_fee_per_kb / 1000), 'btc'),
        'medium': CryptoCurrency.fromSat(Math.round(data.medium_fee_per_kb / 1000), 'btc'),
        'high': CryptoCurrency.fromSat(Math.round(data.high_fee_per_kb / 1000), 'btc'),
      }
    } else {
      const fees = {
        'dag': {
          'low': CryptoCurrency.fromSat('1', 'dag'),
          'medium': CryptoCurrency.fromSat('1', 'dag'),
          'high': CryptoCurrency.fromSat('1', 'dag'),
        },
        /*
        'dgb': {
          'low': CryptoCurrency.fromSat('10', 'dgb'),
          'medium': CryptoCurrency.fromSat('20', 'dgb'),
          'high': CryptoCurrency.fromSat('30', 'dgb'),
        }
        */
      }
      return fees[symbol]
    }
  }
  
  public async postTransaction(tx: any, symbol: CryptoSymbol) {
    switch (symbol) {
      
      case 'dag': {
        const res = await  dagNetwork.l1Api.postTransaction(tx)
        console.log(res)
        return res.hash
      }
      
      case 'btc': {
        const { data } = await axios.post(`https://api.bitcore.io/api/BTC/mainnet/tx/send`, {
          rawTx: tx
        })

        return data.txid
      }

      case 'eth': {
        var obj =  {
          "jsonrpc":"2.0",
          "method":"eth_sendRawTransaction",
          "params":[`0x${tx}`],
          "id":1
        }

        const { data: res } = await axios.post(INFURA_URL, {
          ...obj
        })

        if (!res.result) {
          throw new Error('Unable to post a transaction')
        }

        return res.result
      }
    }
  }

  public async getErc20Tokens(address: string, symbol: CryptoSymbol): Promise<ERCToken[]> {
    return []

    return new Promise((res, rej) => {
      setTimeout(function () {
        const tokens = response.tokens.map(v => ({
          symbol: v.tokenInfo.symbol,
          address: v.tokenInfo.address,
          decimals: parseInt(v.tokenInfo.decimals),
          name: v.tokenInfo.name,
          sat: v.rawBalance
        } as ERCToken))
        res(tokens)
      }, 1000)
    })

    /*
    const res = await axios.get(`https://api.ethplorer.io/getAddressInfo/${address}?apiKey=freekey&showETHTotals=false`)
    const data = res.data as EthplorerResponse

    const tokens = data.tokens.map(v => ({
      symbol: v.tokenInfo.symbol,
      address: v.tokenInfo.address,
      decimals: v.tokenInfo.decimals,
      sat: v.rawBalance
    } as ERCToken))

    return tokens
    */
  }
  
  public async fetchHistory(address: string, symbol: CryptoSymbol) {


    switch (symbol) {
      /*
      case 'dgb': {
        const node = new InsightNode(symbol)
        return node.getHistory(address)
      }
      */
        
      case 'dag':

        let txes: TransactionV2[] = []
        try {
          //@ts-ignore
          txes = (await dagNetwork.blockExplorerV2Api.getTransactionsByAddress(address, 5)).data
        }
        catch (exc) {
          console.log(JSON.stringify(exc))
        }

        
        return txes.map((v: TransactionV2) => ({
          amount: CryptoCurrency.fromSat(v.amount, 'dag'),
          senders: [v.source],
          recipients: [v.destination],
          timestamp: ((new Date(v.timestamp)).getTime() / 1000).toString(),
          fee: CryptoCurrency.fromSat(v.fee, 'dag'),
          hash: v.hash,
          confirmations: '1'
        } as Tx))
        
      case 'btc': {
        const { data } = await axios(`https://api.blockcypher.com/v1/btc/main/addrs/${address}/full?limit=5`)
        
        return data.txs.map((v: any) => {
          const inputs = v.inputs.map((v: any) => v.addresses[0])
          const outputs = v.outputs.map((v: any) => v.addresses[0])
          
          return {
            amount: CryptoCurrency.fromSat(v.total, 'btc'),
            senders: inputs,
            recipients: outputs,
            timestamp: ((new Date(v.confirmed)).getTime() / 1000).toString(),
            fee: CryptoCurrency.fromSat(v.fees, 'btc'),
            hash: v.hash,
            confirmations: v.confirmations
          } as Tx
        }).slice(0, 5)
        
      }
        
      
      case 'eth': 
        const url = `${ETHERSCAN_URL}&module=account&action=txlist&address=${address}&startblock=0&endblock=latest&page=1&offset=5&sort=desc`
        const { data } = await axios.get(url)
        
        if (data.message !== 'OK') {
          throw new Error('Unable to fetch tx history')
        }

        return data.result.map((v: any) => ({
          senders: [v.from],
          recipients: [v.to],
          amount: CryptoCurrency.fromSat(v.value, 'eth'),
          fee: CryptoCurrency.fromSat(v.gas, 'eth').mul(v.gasPrice),
          confirmations: v.confirmations,
          hash: v.hash,
          timestamp: v.timeStamp,
          data: v.input
        } as Tx))
    }
  }

  public async fetchRates(symbols: CryptoSymbol[], fiats: FiatSymbol[]): Promise<RateState['rates']> {
    const { data } = await axios.get(`${BALANCER}/pricer/getPriceBatch?symbols=${symbols.join(',')}&currency=${fiats.join(',')}`)
    const state = JSON.parse(JSON.stringify(data).toLowerCase()).prices as RateState['rates']
    return state
  }

  
  public async fetchBalance(address: string, symbol: CryptoSymbol): Promise<string> {


    switch (symbol) {
      case 'btc':
        const res = await axios.get(`https://api.bitcore.io/api/BTC/mainnet/address/${address}/balance`)
        return res.data.confirmed
      /*
      case 'dgb':
        return (await this.getUtxo(address, symbol)).confirmed
      */
      case 'eth': 
        const { data } = await axios.get(`${ETHERSCAN_URL}&module=account&action=balance&address=${address}&tag=latest`)
        if (data.message !== 'OK') {
          throw new Error('Unable to fetch balance')
        }
        return data.result
      
      case 'dag':
        const result = await dagNetwork.l0Api.getAddressBalance(address)
        return ((result && result.balance) || "0").toString()
      
      default:
        throw new Error("Error")
    }
  }
}