import React from "react";
import { ThemeProvider } from "theme-ui"
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from "react-router-dom";
import { SuspenseWithChunkError, PageLoader } from "components";
import { ToastProvider } from "widgets/Toast";
import store from "state";
import { theme } from "theme";
import history from "./routerHistory";
import { ModalProvider } from "widgets/Modal";
import { LanguageProvider } from "contexts/localization";
import { WalletProvider } from 'contexts/wallet'
import { CryptoDataProvider } from "contexts/cryptodata";
import { StorageProvider } from "contexts/storage";

const Providers:React.FC<any> = ({ children }) => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <WalletProvider name="BitfiV2">
              <CryptoDataProvider>
                <StorageProvider>
                  <LanguageProvider>
                    <ToastProvider>
                      <ModalProvider>
                          {children}
                      </ModalProvider>
                    </ToastProvider>
                  </LanguageProvider>
                </StorageProvider>
              </CryptoDataProvider>
            </WalletProvider>
          </SuspenseWithChunkError>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default Providers