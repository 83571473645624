import React, { cloneElement, Children, ReactElement } from "react";
import { space } from "styled-system";
import { Box, Flex, Text } from "theme-ui";
import { CogIcon } from "..";
import { Button, ButtonVariant } from "../Button";
import { ButtonMenuProps } from "./types";

interface StyledButtonMenuProps extends ButtonMenuProps {

}

const StyledButtonMenu: React.FC<StyledButtonMenuProps> = ({ sx, fullWidth, disabled, ...props }) => {
  const disabledSx = disabled? 
    {
      opacity: 0.5,

      '& > button:disabled': {
        backgroundColor: 'transparent',
        color: 'primary'
      }
    } :
    {}

  return (
    <Box
      sx={{
        display: (fullWidth ? "flex" : "inline-flex"),

        '& button, & > a': {
          flex: (fullWidth ? 1 : 'auto')
        },

        '& > button + button, & > a + a': {
          marginLeft: '2px' // To avoid focus shadow overlap
        },

        '& > button, & a': {
          boxShadow: 'none'
        },

        ...disabledSx,
        ...sx
      }}
      {...props}
    />
  )
}


const ButtonMenu: React.FC<ButtonMenuProps> = ({
  activeIndex = 0,
  //scale = scales.MD,
  variant = 'primary',
  onItemClick,
  label,
  disabled,
  option,
  children,
  fullWidth = false,
  ...props
}) => {
  return (
    <Box  sx={{ 
      backgroundColor: 'input', 
      borderRadius: ['default'],

      boxShadow: 'default', 
      overflow: 'hidden',

      
      width: (fullWidth ? "100%" : "auto"), 
    }} {...props}>
      
      <Flex px={3} py={1} m={0} sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
        {
          label && 
            <Flex>
              <Text sx={{ fontSize: [0], color: 'gray.7' }}>{label}</Text>
            </Flex>
        }
        </Box>
        <Box>
          {option}
        </Box>
      </Flex>
      <StyledButtonMenu disabled={disabled} variant={variant} fullWidth={fullWidth} >
        
        {Children.map(children, (child: ReactElement, index) => {
          return cloneElement(child, {
            px: 3,
            isActive: activeIndex === index,
            onClick: onItemClick ? () => onItemClick(index) : undefined,
            //scale,
            variant,
            disabled,
          });
        })}
      </StyledButtonMenu>
    </Box>
  );
};

export default ButtonMenu