import React from 'react'

export const Browser = {
  safari: 'safari',
  firefox: 'firefox',
  ie: 'ie',
  edge: 'edge',
  chrome: 'chrome',
  opera: 'opera'
} as const

export type BrowserType = keyof typeof Browser

export const useCurrentBrowser = (): BrowserType | null => {
  //@ts-ignore
  if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0)
    return Browser.opera
  
  // Firefox 1.0+
  //@ts-ignore
  if (typeof InstallTrigger !== 'undefined')
    return Browser.firefox

  // Safari 3.0+ "[object HTMLElementConstructor]"
  //@ts-ignore
  if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)))
    return Browser.safari

  // Internet Explorer 6-11
  //@ts-ignore
  const isIE = false || !!document.documentMode
  if (isIE)
    return Browser.ie

  // Edge 20+
  //@ts-ignore
  if (!isIE && !!window.StyleMedia)
    return Browser.edge

  // Chrome 1 - 71
  //@ts-ignore
  if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime))
    return Browser.chrome

  return null
}