import React from 'react'
import { Input as ThemeInput } from 'theme-ui'
import { InputProps } from './types'

export const Input: React.FC<InputProps> = ({ sx, variant, ...props }, ref) => {
    return (
      <ThemeInput 
        sx={{ 
          width: '100%',
          ...sx 
        }} 
        variant={`forms.input.variants.${variant}`}
        {...props} 
        //ref={ref} 
      />
    )
}

Input.defaultProps = {
  variant: 'primary'
}