import React, { useState, useEffect } from "react";
import { keyframes } from '@emotion/react'
import { Alert, Close, Box, AlertProps, Progress, Flex } from "theme-ui";

const progressBar = keyframes` 
  0% { width: 100%; }
  100% { width: 0; }
`

const ToastTimoutMsec = 5 * 1000


export const Toast = React.forwardRef<HTMLDivElement, AlertProps & { onDismiss: any }>(
  function Toast({ children, onDismiss, variant, sx, ...props }, ref) {

    const [progress, setProgress] = useState<number>(100)

    useEffect(() => {
      const deadline = Date.now() + ToastTimoutMsec

      const id = setInterval(() => {
        const left = deadline - Date.now()

        if (left <= 0) {
          clearInterval(id)
          onDismiss()
        }
      }, 200)

      return () => clearInterval(id)
    }, [])

    console.log(progress)
    return (
      <Alert
        variant={variant}
        onClick={onDismiss}
        sx={{
          position: 'relative',
          transition: 'ease-out 0.3s',
          margin: [1],
          
          overflow: 'hidden',
          '&:hover': {
            opacity: 0.95,
          },
          ...sx
        }}
        ref={ref}

        {...props}
      >
        <Box sx={{ width: '100%' }}>
          <Box p={2} sx={{ width: '100%' }}>
            {children}
            
          </Box>
          <Progress sx={{ 
            animation: `${progressBar} ${Math.round(ToastTimoutMsec / 1000)}s`,
            animationFillMode: 'both',
            opacity: 0.3,
            width: '100%', 
            color: 'primary',
            backgroundColor: 'transparent' 
          }} value={progress}  max={100} />
        </Box>
        {
          /*
          <Close 
          sx={{ 
            '&:hover': { 
              opacity: 0.7,
              cursor: 'pointer'
            },
            position: 'absolute',
            top: '5px',
            right: '10px'
          }} 
          ml="auto" 
          mr={-2} 
          onClick={onDismiss} 
        />
          */
        }
        
        
      </Alert>
    )
  }
)