import bigInt, { BigNumber } from "big-integer";

/*
function formatRaw(amount: number, decimals: number = 2, prefix: string) {
  return format({ prefix, truncate: decimals, padRight: decimals })(amount)
}
*/

function fromSatoshi(units: BigNumber, decimals: number): string {
  let f = trimRight(formatMoneyFull(units, decimals), '0');
  if (f[f.length - 1] === '.') {
    return f.slice(0, f.length - 1);
  }
  return f;
}

/*
function sat2fiat(sat: string, symbol: CurrencySymbol, rate: string): BigNumber {
  return convertSat(sat, symbol, "USD", rate)
}
*/


/*
function fiat2sat(fiat: string, symbol: CurrencySymbol, rate: string): BigNumber {
  const decimals = Currencies["USD"].decimals
  return convertSat(fiat, "USD", symbol, (1 / parseFloat(rate)).toFixed(decimals) )
}
*/

function toSatoshi(str: string, decimals: number): bigInt.BigInteger {
  if (decimals === 0) {
    return bigInt(str)
  }
  if (!str) return bigInt.zero;
  var negative = str[0] === '-'
  if (negative) {
    str = str.slice(1)
  }
  var decimalIndex = str.indexOf('.')
  if (decimalIndex == -1) {
    if (negative) {
      return (bigInt(str)).multiply(bigInt(10).pow(decimals)).negate()
    }
    return (bigInt(str)).multiply(bigInt(10).pow(decimals))
  }
  if (decimalIndex + decimals + 1 < str.length) {
    str = str.substr(0, decimalIndex + decimals + 1)
  }
  if (negative) {
    //@ts-ignore
    return bigInt(str.substr(0, decimalIndex)).exp10(decimals)
    //@ts-ignore
      .add(bigInt(str.substr(decimalIndex + 1)).exp10(decimalIndex + decimals - str.length + 1)).negate()
  }

  //@ts-ignore
  return bigInt(str.substr(0, decimalIndex)).exp10(decimals)
    //@ts-ignore
    .add(bigInt(str.substr(decimalIndex + 1)).exp10(decimalIndex + decimals - str.length + 1))
}

function formatMoneyFull(units: BigNumber, decimals: number) {
  units = units.toString();
  let symbol = units[0] === '-' ? '-' : ''
  if (symbol === '-') {
    units = units.slice(1);
  }
  let decimal;
  if (units.length >= decimals) {
    decimal = units.substr(units.length - decimals, decimals);
  } else {
    decimal = padLeft(units, decimals, '0')
  }
  return symbol + (units.substr(0, units.length - decimals) || '0') + '.' + decimal
}

function trimRight(str: string, char: string) {
  while (str[str.length - 1] == char) str = str.slice(0, -1);
  return str;
}

function padLeft(str: string, len: number, char: string) {
  while (str.length < len) {
    str = char + str
  }
  return str;
}

bigInt.prototype.exp10 = function(decimals: number) {
  return this.multiply(bigInt(10).pow(decimals))
}

export {
  toSatoshi,
  fromSatoshi
}