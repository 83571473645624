import { merge, Theme } from 'theme-ui'

export const modalTheme = {
  modals: {
    content: {
      px: '1rem',
      flexGrow: 1,
      overflowY: 'hidden',
      padding: [3]
    },
    footer: {
      minHeight: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      px: '1rem',
    },

    title: {
      display: 'flex',
      padding: [3],
      background: 'transparent',
      justifyContent: 'space-between',
      borderColor: '#e2e2e2dd',
    },
    

    variants: {
      default: {
        
        overflow: 'hidden',
        backgroundColor: '#F8F9FB',
        borderRadius: ['0px','default'],
        border: '1px solid',
        borderColor: '#e2e2e2dd',
        boxShadow: 'default',
    
        '&[aria-disabled="true"]': {
          backgroundColor: 'red',
        },
        display: `flex`,
        flexDirection: `column`,
        maxHeight: `80vh`,
        minHeight:['100vh', '16rem' ],
        minWidth: ['100%', '16rem' ],
        maxWidth: 'min(90vw, 64rem)',
        position: `absolute`,
        zIndex: 'modal',
        top: [`0%`, `10%`, `10%`],
      },
      defaultFullScreen: {
        backgroundColor: 'background',
        borderRadius: 0,
        display: `flex`,
        flexDirection: `column`,
        minHeight: '100vh',
        maxHeight: '100vh',
        height: `fill-available`,
        position: `absolute`,
        zIndex: 'modal',
        top: 0,
        width: `100vw`,
      }
    }
    
  },
  zIndices: {
    backdrop: 100,
    modal: 140,
  },
}

export function withModalTheme<T extends Theme>(theme: T) {
  return merge(modalTheme, theme)
}