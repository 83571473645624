import React, { createContext, useCallback, useEffect, useState } from 'react'
import { ChargeLevel, ProviderState, SignStatus, WalletProvider as Provider, WalletProviderProps } from './types'
import { BitfiDump, BitfiV2, ConnectionError, ConnectionStatus, DeviceError, EventType, IDeviceListener } from '@bitfi/bitfi.js'
import { Box, Flex, Heading, Spinner } from 'theme-ui'
import { StatusLayout } from 'components/StatusLayout'

const APPROVE_TIMEOUT_MSEC = 120 * 1000
const CONNECT_TIMEOUT_MSEC = 5 * 60 * 1000
const REQUEST_TIMOUT_MSEC = 7 * 1000

const checkCodeMessage = (code: string) => 
  `Make sure the code on the device is equal to ${code.toUpperCase()} and then approve the request`



export const WalletContext = createContext<ProviderState>({
  signin: () => {},
  signout: () => {},
  authenticated: false,
  //@ts-ignore
  provider: null,
  connected: false,
  code: '',
  //@ts-ignore
  charge: null,
  reconnecting: false
})

const DEVICE_ID_KEY = 'bitfi_device_id'
const SESSION_KEY = 'bitfi_session'
const ENVOY_KEY = 'bitfi_envoy'

export const WalletProvider: React.FC<WalletProviderProps> = ({ children, name }) => {
  
  const [initialized, setInitialized] = useState<boolean>(false)
  const [provider, setProvider] = useState<Provider | null>(null)
  const [reconnecting, setReconnecting] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [listener, setListener] = useState<IDeviceListener | null>(null)
  //const [connected, setConnected] = useState<boolean>(false)
  const [envoy, setEnvoy] = useState<string | null>(null)
  const [charge, setCharge] = useState<ChargeLevel | null>(null)
  const authenticated = provider !== null
  
  const restart = () => {
    console.log(restart)
  }

  const init = async (provider: Provider, envoy: string) => {
    console.log(envoy)
    const dump = await provider.serialize()
    
    const listener = await provider.createListener(
      'wss://env.async360.com', 
      //@ts-ignore
      WebSocket
    )

    listener.subscribe(EventType.Battery, setCharge)
    listener.subscribe(EventType.SessionClosed, signout)
    listener.subscribe(EventType.ConnectionStatus, (status) => {
      switch (status) {
        case ConnectionStatus.Connected:
          setReconnecting(false)
          break
        case ConnectionStatus.Connecting:
        case ConnectionStatus.Disconnected:
          setReconnecting(true)
          break
      }
    })
    listener.subscribe(EventType.ConnectionError, async (err: ConnectionError) => {
      console.log('error')
      console.log(err)

      const newEnvoy = await provider.getDeviceEnvoy(3000)
      console.log(`Regreating new token ${newEnvoy}`)
      localStorage.setItem(ENVOY_KEY, newEnvoy)
      listener.stop()
      init(provider, newEnvoy)
    })
    listener.start(envoy)  

    setListener(listener)
    setProvider(provider)
    setCode(dump.code)
    setEnvoy(envoy)
  }

  useEffect(() => {
    return () => {
      listener && listener.stop()
    }
  }, [])

  useEffect(() => {
    const trySignin = async () => {
      try {
        const deviceId = localStorage.getItem(DEVICE_ID_KEY)
        const dumpraw = localStorage.getItem(SESSION_KEY)
        const envoy = localStorage.getItem(ENVOY_KEY)

        if (deviceId && dumpraw && envoy) {
          const dump = JSON.parse(dumpraw) as Omit<BitfiDump, 'deviceId'>
          
          if (!dump.sharedSecretHash || !dump.eckey || !dump.code) {
            return
          }

          const provider = new BitfiV2(
            process.env.REACT_APP_APESHIFT_URL, 
            process.env.REACT_APP_DEVICE_CHANNEL_PUB_KEY
          )
          
          
          await provider.deserialize({
            deviceId,
            ...dump
          })
          try {
            await provider.getDeviceInfo(3000)
          } catch (exc) {
            if (!(exc instanceof DeviceError && exc.message === 'USER IS BUSY'))
              throw exc
          }
          await init(provider, envoy)
        }
      }
      catch (exc: any) {
        signout()
        console.log('error')
        console.log(exc.toString())  
      }
      finally {
        setInitialized(true)
      }
    }
    if (!provider) {
      trySignin()
    }
  }, [name])

  const signin = async (
    deviceId: string,
    onUpdate: (mes: SignStatus, code?: string) => void
  ) => {
    if (authenticated) {
      throw new Error('Already asigned in')
    }

    const provider = new BitfiV2(
      process.env.REACT_APP_APESHIFT_URL, 
      process.env.REACT_APP_DEVICE_CHANNEL_PUB_KEY, 
      deviceId
    )

    onUpdate && onUpdate(SignStatus.openSession)
    await provider.enable(CONNECT_TIMEOUT_MSEC)
    
    await provider.authorize((code: string) => {
      onUpdate && onUpdate(SignStatus.authorizeApprove, code)
    }, APPROVE_TIMEOUT_MSEC)

    onUpdate && onUpdate(SignStatus.loading)

    let dump = await provider.serialize()
    const envoy = await provider.getDeviceEnvoy(REQUEST_TIMOUT_MSEC)
    localStorage.setItem(DEVICE_ID_KEY, dump.deviceId)
    localStorage.setItem(ENVOY_KEY, envoy)
    //@ts-ignore
    delete dump.deviceId
    localStorage.setItem(SESSION_KEY, JSON.stringify(dump))
    await init(provider, envoy)
  }

  const signout = () => {
    console.log('signout')
    setProvider(null)
    localStorage.removeItem(SESSION_KEY)
    localStorage.removeItem(ENVOY_KEY)
    listener && listener.stop()
  }

  const renderWaiting = () => {
    return (
      <StatusLayout sx={{ justifyContent: 'center' }}>
        <Box>
          
          <Box>
            <Heading>Checking session...</Heading>
          </Box>

        </Box>
      </StatusLayout>
    )
  }
  
  return (
    <WalletContext.Provider 
      //@ts-ignore
      value={{ signin, signout, charge, provider, authenticated, code, reconnecting }}
    >
      {
        initialized?
          children : 
          renderWaiting()
      }
    </WalletContext.Provider>
  )
}
