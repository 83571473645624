import React, { useState, useEffect, useRef } from "react";
import { Button, Overlay, OverlayProps, Battery } from "components";
import throttle from 'lodash.throttle'
import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'
//import { useMatchBreakpoints } from "hooks";
import { Logo } from './components'
import Panel from "./Panel";
import { NavProps } from "./types";
import { FlexProps, Flex, Box, BoxProps } from "theme-ui";
import { MENU_HEIGHT, SIDEBAR_WIDTH_FULL, SIDEBAR_WIDTH_REDUCED } from "./config";

const StyledNav:React.FC<FlexProps & { showMenu: boolean }> = ({ showMenu, ...props }) => {
  return (
    <Flex
      as="nav"
      sx={{
        position: 'fixed',
        top: showMenu ? 0 : `-${MENU_HEIGHT}px`,
        left: 0,
        
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '8px',
        paddingRight: '16px',
        width: '100%',
        height: `${MENU_HEIGHT}px`,
        backgroundColor: 'menu.topBackground',
        borderBottom: 'solid 2px',
        borderColor: 'menu.border',
        zIndex: 'navbar',
        transform: 'translate3d(0, 0, 0)',
        transition: 'top 0.2s'
      }}
      {...props}
    />
  )
}

interface InnerProps extends BoxProps {
  showMenu: boolean,
  isPushed: boolean
}

const Inner: React.FC<InnerProps> = ({ showMenu, isPushed, sx, ...props }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: showMenu ? `${MENU_HEIGHT}px` : 0,
        maxWidth: '100%',


        marginLeft: [
          '0px',
          `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`
        ],


        transition: 'margin-top 0.2s',
        transform: 'translate3d(0, 0, 0)',
        ...sx
      }}
      {...props}
    />
  )
}

const MobileOnlyOverlay: React.FC<OverlayProps> = ({ sx, ...props }) => {
  
  return (
    <Overlay sx={{ 
      position: 'fixed', 
      height: '100%', 
      ...sx
    }} {...props}/>
  )
}

const Menu: React.FC<NavProps> = ({
  links,
  children,
  rateUsd,
  battery,
  setLang,
  currentLang,
  langs
}) => {
  const isXl = useResponsiveValue([false, true]);
  const isMobile = isXl === false;
  const [isPushed, setIsPushed] = useState(!isMobile);
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(window.pageYOffset);

  useEffect(() => {
    /*
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    }
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
    */
  }, []);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <StyledNav showMenu={showMenu}>
        
        {
          
          <Logo
            battery={battery}
            isPushed={isPushed}
            togglePush={() => setIsPushed((prevState: boolean) => !prevState)}
            //isDark={isDark}
            href={homeLink?.href ?? "/"}
          />
        }
        
        
        {
          /*
          <Flex>
          
          <UserBlock account={account} login={login} logout={logout} />
          {profile && <Avatar profile={profile} />}
          </Flex>
          */
        }
        
      </StyledNav>
      <Flex sx={{ position: 'relative' }}>
        <Panel
          battery={battery}
          isPushed={isPushed}
          isMobile={isMobile}
          showMenu={showMenu}
          //isDark={isDark}
          //toggleTheme={toggleTheme}
          langs={langs}
          setLang={setLang}
          rateUsd={rateUsd}
          currentLang={currentLang}
          //cakePriceUsd={cakePriceUsd}
          //cakePriceLink={cakePriceLink}
          pushNav={setIsPushed}
          links={links}
        />
        <Inner isPushed={isPushed} showMenu={showMenu}>
          {children}
        </Inner>
        <MobileOnlyOverlay show={isPushed && isMobile} onClick={() => setIsPushed(false)} role="presentation" />
      </Flex>
    </Box>
  );
};

export default Menu;
