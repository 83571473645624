import { ThemeUIStyleObject } from "theme-ui";

export default {
  nav: {
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    borderRadius: '25px',
    paddingY: [1],
    paddingX: [3],
    borderColor: 'gray.2',

    '&.selected': {
      color: 'white',
      backgroundColor: 'link',
      borderColor: 'link'

    },
    '&.selected:hover': {
      opacity: 1,
      pointerEvents: 'none'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
  
} as Record<string, ThemeUIStyleObject>