import { Theme } from "theme-ui";
import { withButtonTheme } from "./Button/theme";
import { withCardTheme } from "./Card/theme";
import { withInputTheme } from './Input/theme'

export * from './PageLoader'
export * from './SuspenseWithChunkError'
export * from './Button'
export * from './Overlay'
export * from './Card'
export * from './Input'
export * from './Svg'
export * from './Skeleton'
export * from './Dropdown'
export * from './Captcha'
export * from './ButtonMenu'
export * from './CopyButton'
export * from './AccountItem'
export * from './Battery'
export * from './StatusLayout'

export const withComponentsTheme = (theme: Theme) => {
  return (
    withCardTheme(
    withButtonTheme(
    withInputTheme(
      theme
    )))
  )
}