import { Box, BoxProps } from "theme-ui"
import { LinkLabelProps } from "./types"

export const LinkLabel: React.FC<LinkLabelProps> = ({ isPushed, sx, ...props }) => {
  return (
    <Box
      ml={3}
      sx={{
        display: isPushed? 'block' : 'none',
        color: isPushed ? 'menu.text' : "transparent",
        transition: 'color 0.4s',
        //display: isPushed? 'block' : 'none',
        visibility: isPushed? 'visible' : 'hidden',
        flexGrow: 1,
        ...sx
      }}
      {...props}
    />
  )
}