import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M9.5 21v-3L6 14.5V9q0-.6.275-1.125t.8-.8L9 9H8v4.65l3.5 3.5V19h1v-1.85l.925-.925L1.4 4.2l1.4-1.4 18.4 18.4-1.4 1.4-4.95-4.95-.35.35v3Zm7.65-6.7L16 13.15V9h-4.15L8 5.15V3h2v4h4V3h2v5l-1-1h1q.825 0 1.413.587Q18 8.175 18 9v4.45Zm-3.2-3.2Zm-3.25 2.425Z"/>
    </Svg>

  );
};

export default Icon;






