import bigInt, { BigNumber } from "big-integer"
import { FiatCurrencies, FiatSymbol } from "config/currency"
import Core from "./core"
import { CoinType, ICoin } from "./types"

class Fiat<S extends FiatSymbol = any> extends Core implements ICoin {
  readonly symbol: S
  readonly type: CoinType

  private constructor(core: Core, symbol: S) {
    super(core.sat, core.btc, core.decimals)
    this.symbol = symbol
    this.type = CoinType.fiat
  }
  
  private checkType(b: Fiat | BigNumber) {
    if (b instanceof Fiat && (this.symbol !== b.symbol)) {
      throw new TypeError(`Different types: ${this.symbol} and ${b.symbol}`)
    }
  }

  static fromValue<S extends FiatSymbol>(v: string | number, symbol: S) {
    const core = super.fromBtcInit(v, FiatCurrencies[symbol].decimals)
    return new Fiat(core, symbol)
  }
  
  toFormatted(decimals?: number, prefix?: string) {
    return super.toFormatted(decimals || this.decimals, prefix? prefix : this.symbol + ' ')
  }

  isNegative() {
    return bigInt(this.sat).compare(bigInt.zero) !== -1
  }

  mul(b: Fiat | BigNumber): Fiat<S> {
    this.checkType(b)
    return new Fiat(super.mul(b), this.symbol)
  }

  compare(b: Fiat | BigNumber): number {
    this.checkType(b)
    return super.compare(b)
  }

  copy(): Fiat {
    return new Fiat(new Core(this.sat, this.btc, this.decimals), this.symbol)
  }

  negate(): Fiat<S> {
    return new Fiat(super.negate(), this.symbol)
  }

  add(b: Fiat | BigNumber, sat: boolean = false): Fiat<S> {
    this.checkType(b)
    return new Fiat(super.add(b, sat), this.symbol)
  }
}

export default Fiat