
// baseline estimates, used to improve performance
var TX_EMPTY_SIZE = 4 + 1 + 1 + 4
var TX_INPUT_BASE = 32 + 4 + 1 + 4
var TX_INPUT_PUBKEYHASH = 107
var TX_OUTPUT_BASE = 8 + 1
var TX_OUTPUT_PUBKEYHASH = 25

export type Utxo = {
  hash: string,
  value: number,
  script?: string,
  isSegwit?: boolean,
  tx_output_n: number
}

const EMPTY_UTXO = {
  value: 0,
  hash: '',
  tx_output_n: 0
}
var SEGWIT_FACTOR = 0.7;

function inputBytes (input: Utxo): number {
  var size = TX_INPUT_BASE + ((input.script !== undefined && input.script !== null) ? input.script.length : TX_INPUT_PUBKEYHASH)
  //@ts-ignore
  return input.isSegwit ? parseInt(size * SEGWIT_FACTOR) : size
}

function outputBytes (output: Utxo) {
  var size = TX_OUTPUT_BASE + ((output.script !== undefined && output.script !== null) ? output.script.length : TX_OUTPUT_PUBKEYHASH)
  //@ts-ignore
  return output.isSegwit ? parseInt(size * SEGWIT_FACTOR) : size
}
/*
function inputBytes(input) {
  return TX_INPUT_BASE + (input.script ? input.script.length : TX_INPUT_PUBKEYHASH)
}

function outputBytes(output) {
  return TX_OUTPUT_BASE + (output.script ? output.script.length : TX_OUTPUT_PUBKEYHASH)
}
*/
function dustThreshold(output: Utxo, feeRate: number) {
  /* ... classify the output for input estimate  */
  return inputBytes({
    value: 0,
    hash: '',
    tx_output_n: 0
  }) * feeRate
}

function transactionBytes(inputs: Utxo[], outputs: Utxo[]) {
  return TX_EMPTY_SIZE +
    inputs.reduce(function (a, x) { return a + inputBytes(x) }, 0) +
    outputs.reduce(function (a, x) { return a + outputBytes(x) }, 0)
}

function uintOrNaN(v: any) {
  if (typeof v !== 'number') return NaN
  if (!isFinite(v)) return NaN
  if (Math.floor(v) !== v) return NaN
  if (v < 0) return NaN
  return v
}

function sumOrNaN(range: any) {
  return range.reduce(function (a: any, x: any) { return a + uintOrNaN(x.value) }, 0)
}

var BLANK_OUTPUT = outputBytes(EMPTY_UTXO)

function finalize(inputs: Utxo[], outputs: Utxo[], feeRate: number) {
  var bytesAccum = transactionBytes(inputs, outputs)
  var feeAfterExtraOutput = feeRate * (bytesAccum + BLANK_OUTPUT)
  //@ts-ignore
  feeAfterExtraOutput = Math.max(getMinFee(inputs.length, outputs.length), feeAfterExtraOutput)
  var remainderAfterExtraOutput = sumOrNaN(inputs) - (sumOrNaN(outputs) + feeAfterExtraOutput)

  // is it worth a change output?
  if (remainderAfterExtraOutput > dustThreshold(EMPTY_UTXO, feeRate)) {
    outputs = outputs.concat({ value: remainderAfterExtraOutput, hash: '', tx_output_n: 0 })
  }

  var fee = sumOrNaN(inputs) - sumOrNaN(outputs)
  if (!isFinite(fee)) return { fee: feeRate * bytesAccum }

  return {
    inputs: inputs,
    outputs: outputs,
    fee: fee
  }
}

function accumulative(utxos: Utxo[], outputs: Utxo[], feeRate: number) {
  if (!isFinite(uintOrNaN(feeRate))) return {}
  var bytesAccum = transactionBytes([], outputs)

  var inAccum = 0
  var inputs = []
  var outAccum = sumOrNaN(outputs)
  
  for (var i = 0; i < utxos.length; ++i) {
    var utxo = utxos[i]
    var utxoBytes = inputBytes(utxo)
    var utxoFee = feeRate * utxoBytes
    var utxoValue = uintOrNaN(utxo.value)

    // skip detrimental input
    if (utxoFee > utxo.value) {
      if (i === utxos.length - 1) {
        var fee = feeRate * (bytesAccum + utxoBytes)
        //@ts-ignore
        fee = Math.max(fee, getMinFee(inputs.length, outputs.length))
        return { fee }
      }
      continue
    }

    bytesAccum += utxoBytes
    inAccum += utxoValue
    inputs.push(utxo)
    var fee = feeRate * bytesAccum
    //@ts-ignore
    fee = Math.max(fee, getMinFee(inputs.length, outputs.length))
    
    // go again?
    if (inAccum < outAccum + fee) continue

    return finalize(inputs, outputs, feeRate)
  }

  //@ts-ignore
  var fee = feeRate * bytesAccum
  //@ts-ignore
  fee = Math.max(fee, getMinFee(utxos.length, outputs.length))
  return { fee }
}

function getMinFee(nInputs: Utxo[], nOutputs: Utxo[]): number {
  //@ts-ignore
  return nInputs * 180 + nOutputs * 34 + 10 + nInputs
}

function blackjack(utxos: Utxo[], outputs: Utxo[], feeRate: number): any {
  if (!isFinite(uintOrNaN(feeRate))) return {}

  var bytesAccum = transactionBytes([], outputs)

  var inAccum = 0
  var inputs = []
  var outAccum = sumOrNaN(outputs)
  var threshold = dustThreshold(EMPTY_UTXO, feeRate)

  for (var i = 0; i < utxos.length; ++i) {
    var input = utxos[i]
    var inputBytesRes = inputBytes(input)
    var fee = feeRate * (bytesAccum + inputBytesRes)
    //@ts-ignore
    fee = Math.max(fee, getMinFee(inputs.length, outputs.length))
    var inputValue = uintOrNaN(input.value)

    // would it waste value?
    if ((inAccum + inputValue) > (outAccum + fee + threshold)) continue

    bytesAccum += inputBytesRes
    inAccum += inputValue
    inputs.push(input)

    // go again?
    if (inAccum < outAccum + fee) continue

    return finalize(inputs, outputs, feeRate)
  }

  var fee = feeRate * bytesAccum
  //@ts-ignore
  fee = Math.max(fee, getMinFee(utxos.length, outputs.length))
  return { fee }
}

// order by descending value, minus the inputs approximate fee
function utxoScore(x: Utxo, feeRate: any) {
  return x.value - (feeRate * inputBytes(x))
}

function estimateFee(utxos: Utxo[], outputs: Utxo[], feeRate: any) {
  utxos = utxos.concat().sort(function (a, b) {
    return utxoScore(b, feeRate) - utxoScore(a, feeRate)
  })

  // attempt to use the blackjack strategy first (no change output)
  var base = blackjack(utxos, outputs, feeRate)
  if (base.inputs) return base

  // else, try the accumulative strategy
  return accumulative(utxos, outputs, feeRate)
}

export default {
  estimateFee
}