import { Textarea as ThemeTextarea } from 'theme-ui'
import { TextareaProps } from './types'

export const Textarea: React.FC<TextareaProps> = ({ variant = 'primary', ...props }) => {
  return (
    <ThemeTextarea
      variant={`forms.textarea.variants.${variant}`}
      {...props}
    />
  )
}