import React from "react";
import PanelBody from "./PanelBody";
import PanelFooter from "./PanelFooter";
import { SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from "./config";
import { PanelProps, PushedProps } from "./types";
import { Box, BoxProps } from "theme-ui";

interface Props extends PanelProps, PushedProps {
  showMenu: boolean;
  isMobile: boolean;
}

interface StyledPanelProps extends BoxProps {
  showMenu: boolean;
  isPushed: boolean;
}

const StyledPanel: React.FC<StyledPanelProps> = ({ isPushed, showMenu, sx, ...props }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        //paddingTop: showMenu ? "80px" : 0,
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexShrink: 0,
        backgroundColor: 'menu.background',
        width: [
          isPushed? `${SIDEBAR_WIDTH_FULL}px` : 0,
          `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`,
        ],
        height: '100vh',
        borderRight: [
          isPushed ? "2px solid" : 0,
          '2px solid',
        ],
        borderColor: [
          isPushed ? "menu.border" : "transparent",
          'menu.border',
        ],
        zIndex: 'menu',
        overflow: isPushed ? "initial" : "hidden",
        transition: 'padding-top 0.2s, width 0.2s',
        transform: 'translate3d(0, 0, 0)',
        ...sx
      }}
      {...props}
    />
  )
}

const Panel: React.FC<Props> = (props) => {
  const { isPushed, showMenu } = props;
  return (
    <StyledPanel isPushed={isPushed} showMenu={showMenu}>
      <PanelBody {...props} />
      <PanelFooter {...props} />
    </StyledPanel>
  );
};

export default Panel;
