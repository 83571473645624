import React from "react"
import { ButtonProps, Button } from "../Button";
import { ButtonMenuItemProps } from "./types";

interface InactiveButtonProps extends ButtonProps {
  forwardedAs: ButtonProps["as"];
}

const InactiveButton: React.FC<InactiveButtonProps> = ({ sx, ...props }) => {
  return (
    <Button
      sx={{
        backgroundColor: 'transparent',
        color: 'primary',
        fontSize: [1],
  

        '&:hover:not(:disabled):not(:active)': {
          backgroundColor: 'transparent'
        },

        ...sx
      }}
      {...props}
    />
  )
}

const ButtonMenuItem: React.FC<ButtonMenuItemProps> = ({ isActive = false, variant = 'primary', as = "button", ...props }) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant={variant} {...props} />;
  }

  return <Button sx={{ fontSize: [1] }} as={as} variant={variant} {...props} />
}

export default ButtonMenuItem;
