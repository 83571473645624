import { useEffect } from 'react'
import { useIsWindowVisible } from 'hooks'
import { useAccounts } from './hooks'
import { CryptoSymbol } from 'config/currency'
import { Account } from '..'

type BalanceUpdaterProps = {
  frequenciesMsec: {
    [key in CryptoSymbol]?: number
  }
}

let start = true
const ids: Record<string, number> = {}

export const BalanceUpdater: React.FC<BalanceUpdaterProps> = ({ frequenciesMsec }) => {
  const windowVisible = useIsWindowVisible()
  const { updateBalance, data: accounts, add } = useAccounts()
  const addresses = Object.keys(accounts)

  const updateAccount = (account: Account, frequencyMsec: number, visible: boolean) => {
    let last = Date.now()

    const id = setInterval(async () => {
      if (visible) {
        console.log(`Updating ${account.symbol}:${account.address}, last update ${(Date.now() - last) / 1000} secs`)
        last = Date.now()
        await updateBalance(account.address)
      }
    }, frequencyMsec)
    return id
  }

  useEffect(() => {
    console.log('START INITIAL UPDATE')
    Promise.all(Object.keys(accounts).map(updateBalance))
  }, [])

  useEffect(() => {
    if (windowVisible) {
      const accountVisible = true

      for(const acc of Object.values(accounts)) {
        if (frequenciesMsec[acc.symbol]) {
          //@ts-ignore
          const id = updateAccount(acc, frequenciesMsec[acc.symbol], accountVisible)
          //@ts-ignore
          ids[acc.address] = id
        }
      }
    }
    
    return () => {
      console.log('STOP UPDATERS')
      Object.values(ids).map(clearTimeout)
    }
  }, [windowVisible, JSON.stringify(addresses)])

  return null
}
