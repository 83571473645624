import React from "react";
import { Box, BoxProps } from "theme-ui";

export const ToastContainer = React.forwardRef<HTMLDivElement, BoxProps>(
  function ToastContainer({ sx, ...props}, ref) {
    return (
      <Box
        variant="toasts.default"
        sx={{
          zIndex: 'toast',
          ...sx
        }}
        {...props} 
      />
    )
  }
)