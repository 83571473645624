
   
import { merge, Theme, ThemeUIStyleObject } from 'theme-ui'
import { withModalTheme } from 'widgets/Modal'
import { withToastTheme } from 'widgets/Toast'
import { withComponentsTheme } from 'components'
import colors from './colors'
import fonts from './fonts'
import forms from './forms'
import text from './text'
import links from './links'
import styles from './styles'
import { withMenuTheme } from 'widgets/Menu'

const baseTheme: Theme = {
  colors,
  ...fonts,
  breakpoints: [
    "576px",
    "768px",
    "992px",
    "1200px"
  ],
  zIndices: {
    overlay: 19,
    modal: 20,
    toast: 33,
    menu: 20,
    navbar: 17
  },
  space: [
    "0rem",
    "0.25rem",
    "0.5rem",
    "0.9rem",
    "1.5rem",
    "3rem"
  ],
  lineHeights: {
    "body": 1.5,
    "heading": 1.2
  },
  sizes: {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140
  },
  shadows: {
    input: 'inset 0px 2px 2px -1px #c4c4d619',
    default: "0 1px 1px 0 rgb(118 135 154 / 24%), 0 3px 8px 0 rgb(118 135 154 / 8%)",
    sm: "0 .125rem .25rem rgba(0, 0, 0, .075)",
    lg: "0 1rem 3rem rgba(0, 0, 0, .175)"
  },
  radii: {
    default: "0.8rem",
    sm: "0.2rem",
    lg: "0.3rem",
    pill: "50rem"
  },

  //ts-ignore
  toasts: {
    success: {
    }
  },

  forms,
  text,
  links,
  styles
} as any

export const theme = 
  withModalTheme(
  withToastTheme(
  withComponentsTheme(
  withMenuTheme({
    ...baseTheme
  }))))

export type MyTheme = typeof theme