export const response = {
  "address": "0x12884a28b943bf42bead49e3a7627e109dfb12fb",
  "ETH": {
  "price": {
  "rate": 1063.5285235757117,
  "diff": -1.38,
  "diff7d": -11.86,
  "ts": 1656794880,
  "marketCapUsd": 129096327609.12526,
  "availableSupply": 121384922.6865,
  "volume24h": 10475632725.327347,
  "volDiff1": -37.45599899273142,
  "volDiff7": -14.567403356358994,
  "volDiff30": -11.44413095331143,
  "diff30d": -41.82905558993435
  },
  "balance": 0.001067325073741055,
  "rawBalance": "1067325073741055",
  "totalIn": 0.14436951296515255,
  "totalOut": 0.1433021878914115
  },
  "countTxs": 182,
  "tokens": [
  {
  "tokenInfo": {
  "address": "0x4470bb87d77b963a013db939be332f927f2b992e",
  "name": "AdEx",
  "decimals": "4",
  "symbol": "ADX",
  "totalSupply": "0",
  "owner": "",
  "lastUpdated": 1653991306,
  "issuancesCount": 0,
  "holdersCount": 9198,
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 337306,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "337306"
  },
  {
  "tokenInfo": {
  "address": "0x957c30ab0426e0c93cd8241e2c60392d08c6ac8e",
  "name": "Modum",
  "decimals": "0",
  "symbol": "MOD",
  "totalSupply": "21266200",
  "owner": "0x5158e075b12529a8c8eb3893c55a319eb608fb90",
  "lastUpdated": 1653997611,
  "issuancesCount": 0,
  "holdersCount": 11965,
  "image": "/images/MOD957c30ab.png",
  "website": "https://modum.io/",
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 7,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "7"
  },
  {
  "tokenInfo": {
  "address": "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
  "name": "Status",
  "decimals": "18",
  "symbol": "SNT",
  "totalSupply": "6804870174878168246198837603",
  "owner": "",
  "lastUpdated": 1656791498,
  "issuancesCount": 0,
  "holdersCount": 82986,
  "image": "/images/status.png",
  "description": "https://status.im",
  "website": "https://status.im/",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.02947440909329479,
  "diff": 1.75,
  "diff7d": -0.87,
  "ts": 1656794760,
  "marketCapUsd": 102290458.91915934,
  "availableSupply": 3470483788,
  "volume24h": 12978825.446082,
  "volDiff1": -22.913638961934353,
  "volDiff7": 11.639100131129183,
  "volDiff30": 12.868512831953154,
  "diff30d": -28.346321067409065,
  "bid": 0.05906,
  "currency": "USD"
  }
  },
  "balance": 238335431660541770000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "238335431660541757992"
  },
  {
  "tokenInfo": {
  "address": "0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a",
  "name": "Populous",
  "decimals": "8",
  "symbol": "PPT",
  "totalSupply": "5325224600000000",
  "owner": "0x9cc9c3de8e710781983a65b90b7efeaad2d3d7d8",
  "lastUpdated": 1656792295,
  "issuancesCount": 0,
  "holdersCount": 29237,
  "description": "http://populous.co/",
  "website": "https://populous.world",
  "image": "/images/PPTd4fa1460.png",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.10452937930883936,
  "diff": -7.32,
  "diff7d": -28.6,
  "ts": 1656794760,
  "marketCapUsd": 5566424.220595176,
  "availableSupply": 53252245.99438964,
  "volume24h": 168691.84849319,
  "volDiff1": 15.848687317856559,
  "volDiff7": 35.34341843329008,
  "volDiff30": 32.477328319638985,
  "diff30d": -28.595122512001254,
  "bid": 0.216491,
  "currency": "USD"
  }
  },
  "balance": 171174516,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "171174516"
  },
  {
  "tokenInfo": {
  "address": "0x3839d8ba312751aa0248fed6a8bacb84308e20ed",
  "name": "Bezop",
  "decimals": "18",
  "symbol": "BEZ",
  "totalSupply": "89267250000000000000000000",
  "owner": "0x634f8c7c2ddd8671632624850c7c8f3e20622f5f",
  "lastUpdated": 1648973249,
  "issuancesCount": 0,
  "holdersCount": 6866,
  "website": "https://bezop.io",
  "image": "/images/BEZ3839d8ba.png",
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 134225080795445130000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "134225080795445137483"
  },
  {
  "tokenInfo": {
  "address": "0xb8c77482e45f1f44de1745f52c74426c631bdd52",
  "name": "Binance Coin",
  "decimals": "18",
  "symbol": "BNB",
  "totalSupply": "16579517055253348798759097",
  "owner": "0x00c5e04176d95a286fcce0e68c683ca0bfec8454",
  "lastUpdated": 1656783452,
  "issuancesCount": 6,
  "holdersCount": 322130,
  "website": "https://www.binance.com/",
  "image": "/images/BNBb8c77482.png",
  "ethTransfersCount": 230,
  "price": {
  "rate": 218.16895140803905,
  "diff": -0.51,
  "diff7d": -7.58,
  "ts": 1656794640,
  "marketCapUsd": 35621966344.104095,
  "availableSupply": 163276974.63,
  "volume24h": 792476037.0828172,
  "volDiff1": -33.41183487506612,
  "volDiff7": -12.353030135431581,
  "volDiff30": -99.62342739304961,
  "diff30d": -27.882143848964148,
  "bid": 397.55,
  "currency": "USD"
  },
  "publicTags": [
  "Exchange Token",
  "CEX",
  "Binance Ecosystem"
  ]
  },
  "balance": 10814491033665052,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "10814491033665053"
  },
  {
  "tokenInfo": {
  "address": "0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74",
  "name": "Waltonchain",
  "decimals": "18",
  "symbol": "WTC",
  "totalSupply": "70000000000000000000000000",
  "owner": "",
  "lastUpdated": 1653110238,
  "issuancesCount": 0,
  "holdersCount": 26094,
  "image": "/images/WTCb7cb1c96.png",
  "website": "http://www.waltonchain.org/",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.1768113534123011,
  "diff": -0.05,
  "diff7d": -5.32,
  "ts": 1656794760,
  "marketCapUsd": 14213166.875308987,
  "availableSupply": 80386053.2766,
  "volume24h": 488298.99162265955,
  "volDiff1": 16.019002773770552,
  "volDiff7": -8.358598341427452,
  "volDiff30": -52.830456515753646,
  "diff30d": -4.485450114689158,
  "bid": 0.385242,
  "currency": "USD"
  }
  },
  "balance": 1409266979845418200,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "1409266979845418266"
  },
  {
  "tokenInfo": {
  "address": "0x4156d3342d5c385a87d264f90653733592000581",
  "name": "SALT",
  "decimals": "8",
  "symbol": "SALT",
  "totalSupply": "12000000000000000",
  "owner": "",
  "lastUpdated": 1653996599,
  "issuancesCount": 0,
  "holdersCount": 68552,
  "image": "/images/SALT4156d334.png",
  "website": "https://www.saltlending.com/",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.02800292884878378,
  "diff": -4.93,
  "diff7d": -2.28,
  "ts": 1656791760,
  "marketCapUsd": 2248176.3644747203,
  "availableSupply": 80283615.21092688,
  "volume24h": 80.87110576,
  "volDiff1": -60.405823373317055,
  "volDiff7": -42.5827010116082,
  "volDiff30": 12.02226097026356,
  "bid": 0.072272,
  "currency": "USD"
  }
  },
  "balance": 753791942,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "753791942"
  },
  {
  "tokenInfo": {
  "address": "0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6",
  "name": "Raiden Network Token",
  "decimals": "18",
  "symbol": "RDN",
  "totalSupply": "99999999999999954960000000",
  "owner": "",
  "lastUpdated": 1656770191,
  "issuancesCount": 0,
  "holdersCount": 16615,
  "image": "/images/RDN255aa6df.jpg",
  "website": "https://raiden.network/",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.06578602022492325,
  "diff": -1.42,
  "diff7d": -5.38,
  "ts": 1656794820,
  "marketCapUsd": 4394106.895438784,
  "availableSupply": 66793930.99651379,
  "volume24h": 36636.6141483,
  "volDiff1": -60.37155402116443,
  "volDiff7": 47.67275804170836,
  "volDiff30": 167.17515371358508,
  "diff30d": 53.13010085839585,
  "bid": 0.209263,
  "currency": "USD"
  }
  },
  "balance": 5226178879718827000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "5226178879718826664"
  },
  {
  "tokenInfo": {
  "address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
  "name": "0x",
  "decimals": "18",
  "symbol": "ZRX",
  "totalSupply": "1000000000000000000000000000",
  "owner": "",
  "lastUpdated": 1656791303,
  "issuancesCount": 0,
  "holdersCount": 193737,
  "description": "0x (ZRX) is an open-source protocol that provides smart contract infrastructure and liquidity to enable the peer-to-peer exchange of tokens on the Ethereum blockchain.",
  "website": "https://0x.org/",
  "image": "/images/zrx.png",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.29927300981723565,
  "diff": -3.22,
  "diff7d": -1.54,
  "ts": 1656794760,
  "marketCapUsd": 253632695.12905827,
  "availableSupply": 847496054.8027713,
  "volume24h": 19043607.72333195,
  "volDiff1": -58.14988356752046,
  "volDiff7": 130.9066757462761,
  "volDiff30": -20.736596039815907,
  "diff30d": -25.113406332784862,
  "bid": 0.782819,
  "currency": "USD"
  },
  "publicTags": [
  "Protocol",
  "DeFi",
  "Governance",
  "DEX",
  "Polkadot"
  ]
  },
  "balance": 4224399101070067000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "4224399101070066997"
  },
  {
  "tokenInfo": {
  "address": "0xa74476443119a942de498590fe1f2454d7d4ac0d",
  "name": "Golem Network Token",
  "decimals": "18",
  "symbol": "GNT",
  "totalSupply": "426705991271669122035063373",
  "owner": "",
  "lastUpdated": 1656784862,
  "issuancesCount": 0,
  "holdersCount": 82471,
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 19219653753604210000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "19219653753604209356"
  },
  {
  "tokenInfo": {
  "address": "0x960b236a07cf122663c4303350609a66a7b288c0",
  "name": "Aragon [OLD]",
  "decimals": "18",
  "symbol": "ANT [OLD]",
  "totalSupply": "39609523809523809540000000",
  "owner": "",
  "lastUpdated": 1656763141,
  "issuancesCount": 0,
  "holdersCount": 17946,
  "ethTransfersCount": 0,
  "price": false,
  "publicTags": [
  "Governance"
  ]
  },
  "balance": 1520394824625425700,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "1520394824625425745"
  },
  {
  "tokenInfo": {
  "address": "0x905e337c6c8645263d3521205aa37bf4d034e745",
  "name": "Doc.com",
  "decimals": "18",
  "symbol": "MTC",
  "totalSupply": "1000000000000000000000000000",
  "owner": "0x7a46c781b593068d5e987b191e9c2f7413e22aee",
  "lastUpdated": 1653477488,
  "issuancesCount": 0,
  "holdersCount": 23188,
  "image": "/images/MTC905e337c.png",
  "website": "https://doc.com/",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.0024300465580912546,
  "diff": 3.25,
  "diff7d": -15.81,
  "ts": 1656794820,
  "marketCapUsd": 1879836.3830760312,
  "availableSupply": 773580397.7980567,
  "volume24h": 152151.3355969122,
  "volDiff1": 56827.81533434301,
  "bid": 0.00409423,
  "volDiff7": 687442.0877405277,
  "volDiff30": 1067.3013140419032,
  "currency": "USD"
  },
  "publicTags": [
  "Healthcare"
  ]
  },
  "balance": 1.98e+21,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "1980000000000000000000"
  },
  {
  "tokenInfo": {
  "address": "0x8a1e3930fde1f151471c368fdbb39f3f63a65b55",
  "name": "Bezop",
  "decimals": "18",
  "symbol": "Bez",
  "totalSupply": "89267250000000000000000000",
  "owner": "0xc938765b8d30e9f9a46bf1303403083ed798e038",
  "lastUpdated": 1639746715,
  "issuancesCount": 0,
  "holdersCount": 6450,
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 124225080795445000000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "124225080795445000000"
  },
  {
  "tokenInfo": {
  "address": "0x2ff2b86c156583b1135c584fd940a1996fa4230b",
  "name": "findtherabbit.me",
  "decimals": "18",
  "symbol": "findtherabbit.me",
  "totalSupply": "1000000000000000000000000000000000",
  "owner": "0x0039f22efb07a647557c7c5d17854cfd6d489ef3",
  "lastUpdated": 1656687727,
  "issuancesCount": 0,
  "holdersCount": 78006,
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 1.619771637e+27,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "1619771637000000000000000000"
  },
  {
  "tokenInfo": {
  "address": "0x77fe30b2cf39245267c0a5084b66a560f1cf9e1f",
  "name": "Azbit",
  "decimals": "18",
  "symbol": "AZ",
  "totalSupply": "62850058312999999999999944022",
  "owner": "0xacee5fec2e8861507a09c4f7a592dec36294dd48",
  "lastUpdated": 1654004178,
  "issuancesCount": 5,
  "holdersCount": 352924,
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 8.888e+21,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "8888000000000000000000"
  },
  {
  "tokenInfo": {
  "address": "0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a",
  "name": "Smart Valor",
  "decimals": "18",
  "symbol": "VALOR",
  "totalSupply": "75000000000000000000000000",
  "owner": "",
  "lastUpdated": 1656608719,
  "issuancesCount": 8,
  "holdersCount": 2569,
  "image": "/images/VALOR297e4e5e.png",
  "website": "https://smartvalor.com/",
  "ethTransfersCount": 0,
  "price": {
  "rate": 0.21641492638421211,
  "diff": -1.25,
  "diff7d": -8.27,
  "ts": 1656794760,
  "marketCapUsd": 4336089.465077356,
  "availableSupply": 20036000.0002,
  "volume24h": 107547.84450474,
  "volDiff1": -54.57190885011672,
  "volDiff7": 112.52540399542886,
  "volDiff30": -22.90926961251776,
  "diff30d": -4.991434962278234,
  "bid": 0.454468,
  "currency": "USD"
  },
  "publicTags": [
  "DEX"
  ]
  },
  "balance": 1000000000000000000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "1000000000000000000"
  },
  {
  "tokenInfo": {
  "address": "0xc01e52d1c8b6e26334ad45ba731ac7c20fc29d97",
  "name": "VOTE NO - modumtokenswap.com",
  "decimals": "0",
  "symbol": "MODVOTE",
  "totalSupply": "21266200",
  "owner": "0x78135f054bf1243f1d5f8c0b36286e1cc18fe83c",
  "lastUpdated": 1586518278,
  "issuancesCount": 0,
  "holdersCount": 1174,
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 7,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "7"
  },
  {
  "tokenInfo": {
  "address": "0xa2dca1505b07e39f96ce41e875b447f46d50c6fc",
  "name": "Ethercash",
  "decimals": "18",
  "symbol": "ETHS",
  "totalSupply": "600000000000000000000000000",
  "owner": "",
  "lastUpdated": 1648945751,
  "issuancesCount": 0,
  "holdersCount": 112250,
  "image": "/images/ETHSa2dca150.jpg",
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 1000000000000000000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "1000000000000000000"
  },
  {
  "tokenInfo": {
  "address": "0xc4c06df5c799bec6ee6e4652beec86e5cfc94778",
  "name": "BTF",
  "decimals": "0",
  "symbol": "BTF",
  "totalSupply": "100000000",
  "owner": "0x0000000000000000000000000000000000000000",
  "lastUpdated": 1580630898,
  "issuancesCount": 0,
  "holdersCount": 6,
  "price": false
  },
  "balance": 10,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "10"
  },
  {
  "tokenInfo": {
  "address": "0xc12d1c73ee7dc3615ba4e37e4abfdbddfa38907e",
  "name": "KickToken (OLD)",
  "decimals": "8",
  "symbol": "KICK (OLD)",
  "totalSupply": "125123346789819622107",
  "owner": "0x532b82d5276242008895face88f8057dc7b49c93",
  "lastUpdated": 1649148332,
  "issuancesCount": 0,
  "holdersCount": 1250904,
  "website": "https://www.kickico.com/",
  "image": "/images/KICKc12d1c73.png",
  "ethTransfersCount": 0,
  "price": false
  },
  "balance": 88888800000000,
  "totalIn": 0,
  "totalOut": 0,
  "rawBalance": "88888800000000"
  }
  ]
  }