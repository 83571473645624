import { Global } from '@emotion/react'

export default ({ ...props }) => (
  <Global
    styles={(theme) => ({



      '.g-recaptcha *': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent',
      },
    })}
    {...props}
  />
)