import React, { Component, lazy, useEffect } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { links, Menu } from 'widgets/Menu'
import { useTranslation } from 'contexts/localization'
import { languageList } from 'config/localization/languages'
import { Updater as RateUpdater, useRate } from 'state/rate'
import { Box, Flex, Heading, Spinner } from 'theme-ui'
import { useWallet } from 'contexts/wallet'
import { useAccounts } from 'state/accounts'
import { BalanceUpdater } from 'state/accounts/balanceUpdater'
import { StatusLayout } from './components'

const Home = lazy(() => import('./pages/Home/Home'))
const Auth = lazy(() => import('./pages/Auth/Auth'))
const Account = lazy(() => import('./pages/Account/AccountPage'))

const Updaters = () => {
  return (
    <>
      <BalanceUpdater frequenciesMsec={{
        'btc': 30000,
        'eth': 20000,
        'dag': 10000,
        //'dgb': 10000
      }}  />
      <RateUpdater frequencyMsec={10000} symbols={['btc', 'eth', 'dag'/*, 'dgb'*/]} fiats={['usd']} />
    </>
  )
}

const PrivateRoute: React.FC<any> = ({ authenticated, Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      authenticated ?
        <Component {...props} /> : 
        <Redirect to="/signin" />
    )} />
  );
};

const Dashboard = () => {
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { path, url } = useRouteMatch()
  const { rates } = useRate()
  const { charge, reconnecting } = useWallet()
  const { pull, fetching } = useAccounts()

  useEffect(() => {
    pull()
  }, [])

  if (fetching) {
    return (
      <StatusLayout sx={{ justifyContent: 'center'}}>
        <Box>
          <Heading>Fetching accounts...</Heading>
        </Box>
      </StatusLayout>
    )
  }

  return (
    <>    
      <Updaters/>
      {
        false && reconnecting &&
        <Flex sx={{ 
          position: 'absolute', 
          alignItems: 'center', 
          boxShadow: 'default',
          justifyContent: 'center', 
          top: 0, 
          height: '15px', 
          fontSize: '13px', 
          zIndex: 999, 
          width: '100%', 
          bg: 'muted', 
          textAlign: 'center' 
        }}>
          <Box>
            <Spinner size={'10'}/> waiting for network ...
          </Box>
        </Flex>
      }
      
      <Menu
        battery={charge}
        rateUsd={rates['btc']?.usd}
        links={links}
        currentLang={currentLanguage.code}
        langs={languageList}
        
        setLang={setLanguage}
      >
        
        <Box p={3}>
          
          <Box px={[0, 1, 3]}>
            <Switch>
              <Route exact strict path={`${path}/account/:address`} component={Account} />
              <Route exact strict path={`${path}`} component={Home} />
            </Switch>
          </Box>
        </Box>
      </Menu>
    </>
  )
}

export default function App() {
  const { authenticated } = useWallet()
  
  return (
    <Switch>
      
      <PrivateRoute 
        path="/dashboard"
        authenticated={authenticated} 
        Component={Dashboard} 
      />
      <Route path="/signin" render={props => (
        !authenticated ?
          <Auth/> : 
          <Redirect to="/dashboard" />
      )} />
      <Route path="/" render={props => (
        !authenticated ?
          <Redirect to="/signin" /> : 
          <Redirect to="/dashboard" />
      )} />
    </Switch>
  )
}