import { useSelector } from "react-redux"
import { Account, AccountsState, AppState, useAppDispatch } from ".."
import { 
  add as addThunk, 
  pull as pullThunk, 
  updateAccountBalance,
  remove as removeThunk 
} from "./reducer"
import { UseAccountsResponse } from "./types"
import useCryptoDataProvider from "contexts/cryptodata/useCryptoDataProvider"
import { useStorage } from "contexts/storage"

export const useAccounts = (): UseAccountsResponse => {
  const api = useCryptoDataProvider()
  const dispatch = useAppDispatch()
  const { accountsStorage } = useStorage()
  const accountsState = useSelector<AppState, AppState['accounts']>(
    (state) => state.accounts
  )

  const pull = () => dispatch(pullThunk(accountsStorage))
    
  const add = (account: Account) => 
    dispatch(addThunk({ account, storage: accountsStorage }))

  const remove = (id: string) => 
    dispatch(removeThunk({ id, storage: accountsStorage }))

  const updateBalance = (address: string) =>
    dispatch(updateAccountBalance({ address, api, storage: accountsStorage }))

  return { ...accountsState, add, pull, remove, updateBalance }
}