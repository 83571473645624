import bigInt from "big-integer"
import { CryptoSymbol } from "config/currency"
import { CryptoCurrency } from "utils/coin"
import estimateBtc, { Utxo } from "./estimateBtc"
import { Estimator } from "./types"

export const DEFAULT_ETH_SEND_GAS_LIMIT = 21000

const btcLikeEstimateFee = (symbol: CryptoSymbol) => 
({ satPerBlock, amount, inputs, outputs }: any) => {
  const { fee } = estimateBtc.estimateFee(inputs, outputs, satPerBlock)
  return CryptoCurrency.fromSat(fee, symbol)
}

export const estimator: Estimator = {
  'btc': {
    estimateFee: btcLikeEstimateFee('btc')
  },
  /*
  'dgb': {
    estimateFee: btcLikeEstimateFee('dgb')
  },
  */
  'eth': {
    estimateFee: ({ gasPriceSat, gasLimit = DEFAULT_ETH_SEND_GAS_LIMIT }) => {

      return CryptoCurrency.fromSat(bigInt(gasPriceSat).multiply(gasLimit).toString(), 'eth')
    }
  },
  
  'dag': {
    estimateFee: ({ value }) => {
      return value
    }
  }
  
}

export * from './types'