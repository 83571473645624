import { BitfiDump, IBitfiKeyring } from "@bitfi/bitfi.js"
import React from "react"

export type WalletProviderName = 'BitfiV2'
export type WalletProvider = IBitfiKeyring<BitfiDump>

export type ChargeLevel = {
  isCharging: boolean,
  level: number
}

export enum SignStatus {
  openSession = 1,
  requestApprove,
  authorizeApprove,
  loading
}

export type ProviderState = {
  signin: (
    deviceId: string,
    onUpdate: (mes: SignStatus, code?: string) => void
  ) => void,
  signout: () => void,
  provider: WalletProvider,
  authenticated: boolean,
  code: string,
  connected: boolean,
  charge: ChargeLevel,
  reconnecting: boolean
}

export type WalletProviderProps = {
  children: React.ReactNode,
  name: WalletProviderName,
}