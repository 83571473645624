import React, { useState } from "react";
import { Box } from "theme-ui";
import { MENU_ENTRY_HEIGHT } from "../config";
import { MenuEntry } from "./MenuEntry"
import { LinkLabel } from "./LinkLabel";
import { ArrowDropDownIcon, ArrowDropUpIcon } from "components";
import { AccordionContentProps, AccordionProps } from "./types";

const AccordionContent: React.FC<AccordionContentProps> = ({ sx, isPushed, isOpen, maxHeight, ...props }) => {
  return (
    <Box
      sx={{
        maxHeight: isOpen ? `${maxHeight}px` : 0,
        overflow: 'hidden',
        borderColor: isOpen && isPushed ? "menu.border" : "transparent",
        borderStyle: 'solid',
        borderWidth: '1px',
        transition: 'max-height 0.3s ease-out'
      }}
      {...props}
    />
  )
}

export const Accordion: React.FC<AccordionProps> = ({
  label,
  icon,
  isPushed,
  pushNav,
  initialOpenState = false,
  children,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpenState);

  const handleClick = () => {
    if (isPushed) {
      setIsOpen((prevState) => !prevState);
    } else {
      pushNav(true);
      setIsOpen(true);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      // Safari fix
      flexShrink: 0
    }}>
      <MenuEntry onClick={handleClick} className={className}>
        {icon}
        <LinkLabel isPushed={isPushed}>{label}</LinkLabel>
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </MenuEntry>
      <AccordionContent
        isOpen={isOpen}
        isPushed={isPushed}
        maxHeight={React.Children.count(children) * MENU_ENTRY_HEIGHT}
      >
        {children}
      </AccordionContent>
    </Box>
  );
};
