import { useContext } from 'react'
import { ICryptoData } from './types'
import { CryptoDataContext } from './Provider'

const useCryptoDataProvider = (): ICryptoData => {
  const context = useContext(CryptoDataContext)
  return context.provider
}

export default useCryptoDataProvider
