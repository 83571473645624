import React, { useContext } from 'react'

import { Box, BoxProps, Close } from 'theme-ui'
import { Context } from './ModalContext'
import { ModalProps } from './types'

const ModalContainer = React.forwardRef<HTMLDivElement, ModalProps>(
  function ModalFooter({ hideCloseButton, ...props }, ref) {
    const { onDismiss } = useContext(Context)
    return (
      <>
        <Box variant="modals.variants.default" {...props} ref={ref}>
          <Box sx={{ position: 'relative' }} {...props}>
            {props.children}
            {!hideCloseButton && 
            <Close
              sx={{ 
                '&:hover': { 
                  opacity: 0.8 ,
                  cursor: 'pointer'
                },

                position: 'absolute',
                right: 0, 
                top: 0,
                margin: [2],
              }} 
              onClick={onDismiss} 
            />}
          </Box>
        </Box>
        
      </>
      
    )
  }
)

export default ModalContainer