import React from "react";
import { keyframes } from '@emotion/react'
import { space, layout } from "styled-system";
import { SkeletonProps, animation as ANIMATION, variant as VARIANT } from "./types";
import { Box, Flex } from "theme-ui";

const waves = keyframes`
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const Root: React.FC<SkeletonProps> = ({ sx, variant, text, ...props }) => {
  return (
    <Flex
      sx={{
        minHeight: '20px',
        display: text? 'flex' : 'block',
        alignItems: 'center',
        justifyContent: 'center',
        
        backgroundColor: '#7a7878aa',
        borderRadius: variant === VARIANT.CIRCLE ? '50%' : [5],
        ...sx
      }}
      {...props}
    >
      <Box sx={{ color: 'muted', fontSize: '15px' }}>{text}</Box>
    </Flex>
  )
}

const Pulse: React.FC<SkeletonProps> = ({ sx, ...props }) => {
  return (
    <Root 
      sx={{
        animation: `${pulse} 2s infinite ease-out`,
        transform: 'translate3d(0, 0, 0)',
        ...sx
      }}
      {...props}
    />
  )
}

const Waves: React.FC<SkeletonProps> = ({ sx, ...props }) => {
  return (
    <Root 
      sx={{
        position: 'relative',
        overflow: 'hidden',
        transform: 'translate3d(0, 0, 0)',

        '&:before': {
          content: "",
          position: 'absolute',
          backgroundImage: 'linear-gradient(90deg, transparent, rgba(243, 243, 243, 0.5), transparent)',
          top: 0,
          left: '-150px',
          height: '100%',
          width: '150px',
          animation: `${waves} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite`
        },

        ...sx
      }}
      {...props}
    />
  )
}

const Skeleton: React.FC<SkeletonProps> = ({ variant = VARIANT.RECT, animation = ANIMATION.PULSE, ...props }) => {
  if (animation === ANIMATION.WAVES) {
    return <Waves variant={variant} {...props} />;
  }

  return <Pulse variant={variant} {...props} />
};

export default Skeleton;
