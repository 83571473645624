import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isAccountModel, isUxtoModel } from 'config/currency'
import { ICryptoData } from 'contexts/cryptodata'
import { IAccountsStorage } from 'contexts/storage'
import { AccountsState } from 'state/types'
import { Account, AppState } from '..'

const KEY = 'accounts_bitfi'

export const initialState: AccountsState = {
  fetching: true,
  data: {}
}

export const pull = createAsyncThunk<AccountsState['data'], IAccountsStorage>(
  'accounts/pull',
  async (storage) => {
    const res = await storage.get(KEY)
    return res
  }
)

export const add = createAsyncThunk<Account, { account: Account, storage: IAccountsStorage }>(
  'accounts/add',
  async ({ account, storage }, { getState }) => {
    const { accounts: { data } } = getState() as AppState
    
    const res = await storage.put(KEY, {
      ...data,
      [account.address]: account
    })

    return res[account.address]
  }
)

export const remove = createAsyncThunk<string, { id: string, storage: IAccountsStorage }>(
  'accounts/remove',
  async ({ id, storage }) => {
    await storage.remove(KEY, id)
    return id
  }
)

export const updateAccountBalance = createAsyncThunk<Account, { address: string, api: ICryptoData, storage: IAccountsStorage }>(
  'accounts/updateAccountData',
  async ({ address, api, storage }, { getState, dispatch }) => {
    const { accounts: { data } } = getState() as AppState
    const account = data[address] as Account

    if (!account) {
      throw new Error(`Account not found`)
    }
    
    let sat = null
    let tokens = undefined
    let utxos = undefined
    let unconfirmed = undefined

    if (isUxtoModel(account.symbol)) {
      //@ts-ignore
      const balance = await api.getUtxo(account.address, account.symbol)
      unconfirmed = balance.unconfirmed
      sat = balance.confirmed
      utxos = balance.utxo

    } else {
      sat = await api.fetchBalance(account.address, account.symbol)

      if (isAccountModel(account.symbol)) {
        try {
          //@ts-ignore
          tokens = await api.getErc20Tokens(account.address, account.symbol)
        }
        catch (exc) {
          console.log(exc)
          tokens = account.tokens
        }
      }
    } 

    const updatedAccount = {
      ...account,
      sat,
      tokens,
      utxos,
      unconfirmed
    }

    dispatch(add({
      account: updatedAccount,
      storage
    }))
    
    return updatedAccount
  }
)

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(remove.fulfilled, (state, action) => {
      delete state.data[action.payload]

      state.data = {
        ...state.data,
      }
    })

    builder.addCase(updateAccountBalance.pending, (state, action) => {
      state.data[action.meta.arg.address].updating = true
    })
    builder.addCase(updateAccountBalance.fulfilled, (state, action) => {
      state.data[action.meta.arg.address].updating = false
      state.data[action.meta.arg.address] = action.payload
    })

    builder.addCase(add.fulfilled, (state, action) => {
      console.log(action)
      state.data = {
        ...state.data,
        [action.payload.address]: action.payload
      }
    })

    builder.addCase(pull.fulfilled, (state, action) => {
      state.data = action.payload
      state.fetching = false
    })
    builder.addCase(pull.rejected, (state, action) => {
      state.fetching = false
    })
  },
})

export default accountsSlice.reducer