import { CryptoSymbol } from "config/currency";

function currencyHref(symbol: CryptoSymbol) {
  return `/dashboard/account/${symbol}`
}

export const links = [
  {
    label: "Accounts",
    icon: "HomeIcon",
    href: "/dashboard",
  },
  /*
  {
    label: "CurrencySymbol",
    icon: "TradeIcon",
    items: [
      {
        label: "BTC",
        href: currencyHref('btc'),
      },
      {
        label: "ETH",
        href: currencyHref('eth'),
      },
    ],
  }
  */
];

export const socials = [
  /*
  {
    label: "Github",
    icon: "GithubIcon",
    href: "https://github.com",
  },
  
  {
    label: "Docs",
    icon: "GitbookIcon",
    href: "https://github.com",
  },
  
  {
    label: "Telegram",
    icon: "TelegramIcon",
    items: [
      {
        label: "English",
        href: "https://t.me",
      },
      {
        label: "Announcements",
        href: "https://t.me",
      },
    ],
  },
  */
];

export const MENU_HEIGHT = 64;
export const MENU_ENTRY_HEIGHT = 48;
export const SIDEBAR_WIDTH_FULL = 180;
export const SIDEBAR_WIDTH_REDUCED = 56;
