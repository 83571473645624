
import { FC, useEffect } from "react";
import { Box, BoxProps } from "theme-ui";
import { OverlayProps } from "./types";

const BodyLock = () => {
  useEffect(() => {
    document.body.style.cssText = `
      overflow: hidden;
    `;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.cssText = `
        overflow: visible;
        overflow: overlay;
      `;
    };
  }, []);

  return null;
};

export const Overlay: FC<OverlayProps> = ({ show, text, sx, ...props }) => {
  if (!show)
    return <></>

  return (
    <>      
      <BodyLock />
      <Box 
        sx={{
          opacity: 0.2,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: `overlay`,
          transition: 'opacity 0.4s',
          zIndex: 'overlay',
          ...sx
        }}
        role="presentation" 
        {...props} 
      />
    </>
  );
};

export default Overlay;