import './initialize'
import './global.d.ts'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Providers from './Providers'
import GlobalStyle from './GlobalStyle'

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <GlobalStyle/>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);
