import get from 'lodash.get'
import { Box, IconButton, useThemeUI, Text } from 'theme-ui'
import { SvgProps } from './types'

const Svg: React.FC<SvgProps> = ({ sx, onClick, viewBox, xmlns, hoverable, label, color, height, width, children, ...props }) => {
  const { theme } = useThemeUI()
  return (
    <Box
      //@ts-ignore
      onClick={onClick}
      sx={{ 

        '&:hover' : hoverable? { 
          opacity: '0.8',
           cursor: 'pointer' 
          } : {}
        }}
    >
      
      <IconButton 
        type="button" 
        sx={{ 
          height: '100%',
          width: '100%',
          flexShrink: 0,
          cursor: hoverable? 'pointer' : 'default',
          display: 'flex',
          margin: 'auto',
          ...sx 
        }} 
        {...props}
      >
        <svg
          xmlns={xmlns}
          width={width}
          height={height}
          //@ts-ignore
          fill={get(theme, `colors.${color}`, 'black')}
          viewBox={viewBox}
        >
          {children}
        </svg>
      </IconButton>

      {label && <Text sx={{ fontWeight: '550' }}>{label}</Text>}
    </Box>
  )
}

Svg.defaultProps = {
  color: "primary",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
};

export default Svg;
