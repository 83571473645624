import React from 'react'
import { Box, Divider, Text } from 'theme-ui'
import { SwapIcon } from '..'
import { DoubleInputGroupProps } from './types'

export const DoubleInputGroup: React.FC<DoubleInputGroupProps> = ({ firstInput, onSwapClicked, canSwap, label, secondInput, sx, isError, ...props }) => {
  return (
    <Box sx={{ 
      backgroundColor: 'input', 
      borderRadius: ['default'],

      boxShadow: 'default', 
      overflow: 'hidden',

      border: isError && '1px solid',
      borderColor: isError? 'red' : '#e2e2e2dd',
      '& .inputGroup': {
        bg: 'transparent !important',
        border: 'none !important',
        shadow: 'none !important',
        boxShadow: 'none !important',
        borderRadius: 'none !important'
      },
      ...sx
    }} {...props}>
      {label && <Text ml={3} sx={{ fontSize: [0], color: 'gray.7' }}>{label}</Text>}
      <Box>
        {firstInput}
        {secondInput && 
          <Box sx={{ position: 'relative', overflow: 'visible' }}>
            <Box 
              onClick={onSwapClicked}
              sx={{ 
                display: canSwap? 'block' : 'none',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                position: 'absolute',
                zIndex: 2,
                overflow: 'visible',
                right: `calc(50% - 15px)`,
                boxShadow: 'default',
                backgroundColor: 'white',
                top: '-15px',

                '&:hover': {
                  opacity: 0.7
                }
              }}
            >
              <SwapIcon />
            </Box>
            <Divider mx={3} my={0} />
          </Box>
        }
        {secondInput}
      </Box>
    </Box>
  )
}