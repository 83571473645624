import { useEffect } from 'react'
import { useIsWindowVisible } from 'hooks'
import { useRate } from './hooks'
import { CryptoSymbol, FiatSymbol } from 'config/currency'

export const Updater: React.FC<{ frequencyMsec: number, symbols: CryptoSymbol[], fiats: FiatSymbol[] }> = ({ symbols, fiats, frequencyMsec }): null => {
  const windowVisible = useIsWindowVisible()
  const { update } = useRate()

  useEffect(() => {
    if (windowVisible) {
      console.log('REINIT RATE UPDATER')
      update(symbols, fiats)
      const id = setInterval(async () => {
        console.log('UPDATING ALL RATES')
        return await update(symbols, fiats)
      }, frequencyMsec)

      return () => clearInterval(id)
    }
  }, [windowVisible, JSON.stringify(fiats)])

  return null
}
