import { AccountsState } from "state/types";
import { IAccountsStorage } from "./types";

export class LocalStorage implements IAccountsStorage {
  private readonly prefix: string
  constructor(prefix?: string) {
    this.prefix = prefix || ''
  }

  public async remove(key: string, id: string) {
    const obj = await this.get(key)

    if (obj && obj[id]) {
      delete obj[id]
      await this.put(key, obj)
    }
  }

  private getKey(key: string) {
    return `${key}${this.prefix}`
  }

  public async get(key: string): Promise<AccountsState['data']> {
    const raw = localStorage.getItem(this.getKey(key)) || null
    return raw? JSON.parse(raw) : {}
  }

  public async put(key: string, value: AccountsState['data']) {
    localStorage.setItem(this.getKey(key), JSON.stringify(value))
    return this.get(key)
  }
}

