import { merge, Theme } from 'theme-ui'

export const menuTheme = {
  menu: {
    
  },
  colors: {
    menu: {
      topBackground: 'white',
      background: '#1c1c1c',
      backgroundSecondary: '#2c2c2c',
      text: '#fff',
      textSubtle: 'grey',
      border: 'rgba(133, 133, 133, 0.1)'
    },
  },
  shadows: {
    menu: {
      selectedItem: 'inset 4px 0px 0px #ffca21'
    }
  },
  zIndices: {
    menu: 110,
    navbar: 110,
  },
}

export function withMenuTheme<T extends Theme>(theme: T) {
  return merge(menuTheme, theme)
}