import React from 'react'
import { Card as ThemeCard } from 'theme-ui'
import { CardVariant, CardProps } from './types'

export const Card: React.FC<CardProps> = ({ sx, ...props }) => {
  return (
    <ThemeCard sx={{ padding: [3], overflow: 'hidden', ...sx }} {...props}/>
  )
}

Card.defaultProps = {
  variant: 'primary'
}