
   
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import user from './user/reducer'
import rate from './rate/reducer'
import accounts from './accounts/reducer'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    user,
    rate,
    accounts
  },
  middleware: (getDefaultMiddleware: any) => [
    ...getDefaultMiddleware({ thunk: true })
  ]
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()
export * from './types'

export default store