import { merge, Theme } from "theme-ui"
import { InputTheme } from './types'

const inputTheme: InputTheme = {
  forms: {
    textarea: {
      variants: {
        primary: {
          fontFamily: 'Inter, sans-serif',
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: 'default',
          outline: 'none',
          padding: [2],
          fontSize: [2]
        },
        secondary: {

        }
      }
    },
    input: {
      padding: [3],
    
      borderRadius: ['default'],
      borderColor: 'secondary',
  
      '&:hover': {
        borderColor: 'primary'
      },
  
      '&:focus': {
  
        outline: 'none !important',
        borderColor: 'primary'
      },

      variants: {
        primary: {
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: 'default',
          outline: 'none',
          padding: [2]
        },
        secondary: {
          backgroundColor: 'black'
        }
      }
    }
  }
}

export const withInputTheme = (theme: Theme) => {
  return merge(theme, inputTheme)
}