import { BigNumber } from "big-integer"

export interface ICoin {
  readonly btc: string
  readonly sat: string
  readonly symbol: string
  readonly decimals: number
  readonly type: CoinType

  to(rate: ICoin): ICoin
  mul(b: ICoin | BigNumber): ICoin
  compare(b: ICoin | BigNumber): number
  negate(): ICoin
 
  add(b: ICoin | BigNumber, sat?: boolean): ICoin
  isNegative(): boolean
  toFormatted(decimals: number, prefix?: string): string
  copy(): ICoin
}

export enum CoinType {
  fiat,
  CryptoSymbol,
  token
}