import React from "react";
import { useLocation } from "react-router-dom";
import * as IconModule from "./Icons";
import { LogoIcon, SvgProps } from "components";
import { Accordion, MenuEntry, LinkLabel, MenuLink, Logo }  from "./components";
import { PanelProps, PushedProps } from "./types";
import { Box, Flex } from "theme-ui";
import { useTranslation } from "contexts/localization";
import { useUser } from "state/user/hooks";
import { useWallet } from "contexts/wallet";

interface Props extends PanelProps, PushedProps {
  isMobile: boolean;
}

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const PanelBody: React.FC<Props> = ({ isPushed, pushNav, isMobile, links }) => {
  let location = useLocation();
  const { t } = useTranslation()
  const { signout } = useWallet()

  // Close the menu when a user clicks a link on mobile
  const handleClick = isMobile ? () => pushNav(false) : undefined;
  const LogoutIcon = Icons['LogoutIcon']

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%'
    }}>
      <Flex px={2} pb={2} sx={{ height: '80px', alignItems: 'center', justifyContent: isPushed? 'left' : 'center', width: '100%' }}>
        <LogoIcon
          sx={{ width: '100%' }}
        />
      </Flex>
      {links.map((entry) => {

  
        const Icon = Icons[entry.icon];
        const iconElement = <Icon   />;
        const calloutClass = entry.calloutClass ? entry.calloutClass : undefined;

        if (entry.items) {
          const itemsMatchIndex = entry.items.findIndex((item) => item.href === location.pathname);
          const initialOpenState = entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0;

          return (
            <Accordion
              key={entry.label}
              isPushed={isPushed}
              pushNav={pushNav}
              icon={iconElement}
              label={entry.label}
              initialOpenState={initialOpenState}
              className={calloutClass}
            >
              {isPushed &&
                entry.items.map((item) => (
                  <MenuEntry key={item.href} secondary isActive={item.href === location.pathname} onClick={handleClick}>
                    <MenuLink href={item.href}>{t(item.label)}</MenuLink>
                  </MenuEntry>
                ))}
            </Accordion>
          );
        }
        return (
          <MenuEntry sx={{ justifyContent: 'center' }} key={entry.label} isActive={entry.href === location.pathname} className={calloutClass}>
            <MenuLink href={entry.href} onClick={handleClick}>
              {iconElement}
              <LinkLabel isPushed={isPushed}>{t(entry.label)}</LinkLabel>
            </MenuLink>
          </MenuEntry>
        );
      })}

      <MenuEntry sx={{ justifyContent: 'center' }} key={'logout'}>
        <MenuLink href="" onClick={signout}>
          <LogoutIcon />
          <LinkLabel isPushed={isPushed}>{t('logout')}</LinkLabel>
        </MenuLink>
      </MenuEntry>
    </Box>
  );
};

export default PanelBody;
