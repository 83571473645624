import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CryptoSymbol, FiatSymbol } from 'config/currency'
import { ICryptoData } from 'contexts/cryptodata'
import { RateState } from 'state/types'

export const initialState: RateState = {
  loading: false,
  rates: {}
}

export const fetchRates = createAsyncThunk<RateState['rates'], { symbols: CryptoSymbol[], fiats: FiatSymbol[], api: ICryptoData }>(
  'user/fetchRate',
   async ({ symbols, fiats, api }) => {
    const rates = await api.fetchRates(symbols, fiats)
    return rates
  },
)

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRates.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchRates.fulfilled, (state, action) => {
      const rates = action.payload

      state.loading = false
      state.rates = {
        ...state.rates,
        ...rates
      }
    })
    builder.addCase(fetchRates.rejected, (state) => {
      state.loading = false
    })

  },
})

export default rateSlice.reducer