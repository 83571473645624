import { Spinner, Button as ThemeButton } from "theme-ui"
import { ButtonProps } from "./types"

export const Button: React.FC<ButtonProps> = ({ loading, children, ...props }) => {
  return (
    <ThemeButton type="button" disabled={loading} {...props}>
      {loading? <Spinner size={25} sx={{ color: 'white', zIndex: 999 }}/> : children}
    </ThemeButton>
  )
}


Button.defaultProps = {
  variant: "primary"
};

export * from './types'