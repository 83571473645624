export default {
  black: "#000",
  gray: [
    "#fff",
    "#e1e6f0",
    "#e9ecef",
    "#dee2e6",
    "#ced4da",
    "#adb5bd",
    "#6c757d",
    "#495057",
    "#343a40",
    "#212529"
  ],
  input: '#fff', //'#f3f3f3',
  blue: "#007bff",
  indigo: "#6610f2",
  purple: "#763ff9",
  pink: "#e83e8c",
  orange: "#fd7e14",
  yellow: "#ffc107",
  teal: "#20c997",
  cyan: "#17a2b8",
  grayDark: "#343a40",
  text: "#212529",
  background: "#F8F9FB",
  muted: "#babec2",
  success: "#28a745",
  info: "#17a2b8",
  warning: "#ffc107",
  danger: "#dc3545",
  ligh: "#f8f9fa",
  dark: "#343a40",
  textMuted: "#6c757d",
  overlay: 'black',
  
  green: '#28a744',
  red: "#dc3542",
  white: "#fff",
  link: '#763ff9',
  primary: '#212529',
  secondary: '#6c757d'
}