import { useTranslation } from 'contexts/localization'
import { LanguageKey } from 'contexts/localization/types'
import React from 'react'
import Reaptcha, { Props as ReaptchaProps } from 'reaptcha'
import { Box, Flex } from 'theme-ui'

export interface CaptchaProps extends Omit<ReaptchaProps, 'sitekey'> {
  sitekey?: string
}

export type RecaptchaRef = Reaptcha

export const Captcha = React.forwardRef<RecaptchaRef, CaptchaProps>(
  function Captcha({ hl, ...props }, ref) {
    const { currentLanguage } = useTranslation()

    return (
      <Flex py={2} sx={{ width: '100%', justifyContent: 'center' }}>
        <Reaptcha
          
          ref={ref}
          hl={currentLanguage.locale}
          {...props}
        />
      </Flex>
    )
  }
)

console.log('CAPTCHA KEY')
console.log(process.env)
console.log(process.env.REACT_APP_CAPTCHA_KEY)

Captcha.defaultProps = {
  sitekey: process.env.REACT_APP_CAPTCHA_KEY,
}
