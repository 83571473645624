import React, { createContext, useState } from 'react'
import { ICryptoData, ProviderState, ApiProviderProps } from "./types"
import { Balancer } from "./balancer"

export const CryptoDataContext = createContext<ProviderState>({
  provider: new Balancer()
})

export const CryptoDataProvider: React.FC<ApiProviderProps> = ({ children }) => {
  
  const [provider, setProvider] = useState<ICryptoData>(new Balancer())
  
  return (
    <CryptoDataContext.Provider
      value={{ provider }}
    >
      {children}
    </CryptoDataContext.Provider>
  )
}