import React from "react";
import { Text, Flex, IconButton, BoxProps, Box, LinkProps, Link } from 'theme-ui'
import { Button, Skeleton, Dropdown, CogIcon, SvgProps, LanguageIcon, Battery, BTCIcon } from "components";
import { MenuButton } from "./components";
import * as IconModule from "./Icons";
import { socials, MENU_ENTRY_HEIGHT } from "./config";
import { PanelProps, PushedProps } from "./types";

interface Props extends PanelProps, PushedProps {}

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const Container: React.FC<BoxProps> = ({ sx, ...props }) => {
  return (
    <Box 
      sx={{
        flex: 'none',
        padding: '8px 4px',
        backgroundColor: 'menu.background',
        borderTop: 'solid 2px', 
        borderColor: 'menu.border',
        ...sx 
      }} 
      {...props}
    />
  )
}

const PriceLink: React.FC<LinkProps> = ({ sx, ...props }) => {
  return (
    <Link
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          transition: 'transform 0.3s'
        },

        '&:hover svg': {
          transform: 'scale(1.2)' 
        },
        ...sx
      }}

      {...props}
    />
  )
}


const PanelFooter: React.FC<Props> = ({
  isPushed,
  pushNav,
  //toggleTheme,
  //isDark,
  rateUsd,
  //cakePriceLink,
  currentLang,
  langs,
  battery,
  setLang,
}) => {
  if (!isPushed) {
    return (
      <Container >
        
        <CogIcon 
          color={"menu.text"} 
          sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} 
          variant="text" 
          onClick={() => pushNav(true)}  
        />
      </Container>
    );
  }

  return (
    <Container>
      
      {/** social entry **/}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: `${MENU_ENTRY_HEIGHT}px`,
        padding: '0 16px'
      }}>
        {rateUsd ? (
          <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
            <BTCIcon/>
            <PriceLink target="_blank">
              
              <Text sx={{ fontSize: '15px', color: 'menu.text' }}>
                {`$${rateUsd.toFixed(3)}`}
              </Text>
            </PriceLink>
            
          </Flex>
        ) : (
          <Skeleton sx={{ width: '80px', height: '24px' }} />
        )}

        {
          /*
          <Dropdown
            position="top-right"
            target={
              <Flex sx={{ alignItems: 'center', height: '100%' }}>
                <LanguageIcon color="menu.textSubtle" width="24px" />
                <Text color="menu.textSubtle">{currentLang?.toUpperCase()}</Text>
              </Flex>
            }
          >
            {langs.map((lang) => (
              <MenuButton
                key={lang.code}
                variant="link"
                //fullWidth
                onClick={() => setLang(lang)}
                // Safari fix
                style={{ minHeight: "32px", height: "auto" }}
              >
                {lang.language}
              </MenuButton>
            ))}
          </Dropdown>
          */
        }
      </Box>
      
      {/** settings entry */}
      {/*
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: `${MENU_ENTRY_HEIGHT}px`,
          padding: '0 16px'
        }}
      >
        {/*<Button variant="text" onClick={() => toggleTheme(!isDark)}>*/}
        {/*/!* alignItems center is a Safari fix *!/*/}
        {/*<Flex alignItems="center">*/}
        {/*<SunIcon color={isDark ? "textDisabled" : "text"} width="24px" />*/}
        {/*<Text color="textDisabled" mx="4px">*/}
        {/*/*/}
        {/*</Text>*/}
        {/*<MoonIcon color={isDark ? "text" : "textDisabled"} width="24px" />*/}
        {/*</Flex>*/}
        {/*</Button>*/}
        {
          /*
          
        <Flex>
          

          {socials.map((social, index) => {
            const Icon = Icons[social.icon];
            const iconProps = { width: "20px", color: "menu.textSubtle", style: { cursor: "pointer" } };
            const mr = index < socials.length - 1 ? "20px" : 0;
            */
            /*
            if (social.items) {
              return (
                <Dropdown
                  key={social.label}
                  position="top"
                  target={<Icon {...iconProps} mr={mr} style={{ display: "flex", alignItems: "center" }} />}
                >
                  {social.items.map((item) => (
                    <Link key={item.label} href={item.href} aria-label={item.label} color="menu.textSubtle">
                      {item.label}
                    </Link>
                  ))}
                </Dropdown>
              );
            }
            */
           /*
            return (
              <Link key={social.label} href={social.href} aria-label={social.label} mr={mr}>
                <Icon {...iconProps} />
              </Link>
            );
          })</Flex>*/}

        
      {/*</Container></Box>*/}
    </Container>
  );
};

export default PanelFooter;
