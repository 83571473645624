import { Theme, merge } from 'theme-ui'

export const toastTheme = {
  toasts: {
    //modal types
    warning: {
      cursor: "pointer",
      borderRadius: [5],
      boxSizing: 'border-box',
      padding: [0],
      color: 'primary',
      boxShadow: 'default',
      bg: 'yellow',
    },
    error: {
      cursor: "pointer",
      borderRadius: [5],
      boxSizing: 'border-box',
      padding: [0],
      color: 'white',
      boxShadow: 'default',
      bg: 'red',
    },
    success: {
      
      color: 'white',
      cursor: "pointer",
      borderRadius: [5],
      boxSizing: 'border-box',
      padding: [0],
      boxShadow: 'default',
      bg: 'green'
    },

    //container style
    default: { 
      display: 'block',
      position: "fixed", 
      right: [0, 15], 
      width: ['100%', '300px'],
      top: 0
    }
  },
  zIndices: {
    toast: 130,
  },
}

export function withToastTheme<T extends Theme>(theme: T) {
  return merge(toastTheme, theme)
}