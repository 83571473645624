import { Language } from 'contexts/localization'

export const EN: Language = { locale: 'en-US', language: 'English', code: 'en' }
export const PTBR: Language = { locale: 'pt-BR', language: 'Português (Brazil)', code: 'pt-br' }
export const RU: Language = { locale: 'ru-RU', language: 'Русский', code: 'ru' }

export const languages = {
  'en-US': EN,
  'pt-BR': PTBR,
  'ru-RU': RU,
} as const

export const languageList = Object.values(languages)
