import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { Battery, LogoIcon, LogoWithTextIcon } from "components"
import { Box, Flex, Link as ThemeLink, LinkProps as ThemeLinkProps } from 'theme-ui'
import { HamburgerIcon, HamburgerCloseIcon } from "../Icons";
import { ChargeLevel } from "contexts/wallet/types";

interface Props {
  isPushed: boolean;
  togglePush: () => void;
  href: string;
  battery: ChargeLevel
}

const StyledLink: React.FC<ThemeLinkProps & { to?: string, href?: string }> = ({ to, href, sx, ...props }) => {
  return (
    <Link to={to || '/'} href={href}>
      <ThemeLink
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: ['32px', '32px'],
          ...sx
        }}
        {...props}
      />
    </Link>
  )
  
}

export const Logo: React.FC<Props> = ({ isPushed, battery, togglePush, href }) => {
  const isAbsoluteUrl = href.startsWith("http");
  const innerLogo = (
    <>
      
      {<LogoIcon   sx={{ display: isPushed? 'none' : ['block', 'none' ] }}/>}
    </>
  );

  return (
    <Flex sx={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      
      {innerLogo}
      
      {
        <Flex mr={3}>
          <Battery
          {...battery}
        />
        {
          isPushed ? (
            <HamburgerCloseIcon onClick={togglePush} width="24px" color="menu.textSubtle" />
          ) : (
            <HamburgerIcon onClick={togglePush} width="24px" color="menu.textSubtle" />
          )
        }
        </Flex>
      }
    </Flex>
  );
};

