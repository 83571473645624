


import React from "react";
import Svg from "../../Svg";
import { SvgProps } from "../../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 2499.7 2495" {...props}>
      <ellipse cx="1249.9" cy="1247.5" fill="#414141" rx="1249.9" ry="1247.5"/><text fill="#fff" fontFamily="'Arial-BoldMT'" fontSize="1296" transform="translate(889.488 1703.1289)">$</text>
    </Svg>
  );
};

export default Icon;




