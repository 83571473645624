import React, { cloneElement, ReactElement } from "react";
import { Box, Text, Flex } from "theme-ui";
import { InputIcon } from "./InputIcon";
import { InputGroupProps } from "./types";

const getPadding = (hasIcon: boolean) => {
  return !hasIcon? '0px' : '30px'
}

export const InputGroup: React.FC<InputGroupProps> = ({ 
  startIcon, isError, endIcon, 
  option,
  label, children, sx, ...props 
}) => {
  const hasStartIcon = startIcon !== undefined

  const hasEndIcon = endIcon !== undefined

  return (
      <Box
        className="inputGroup"
        px={3}
        sx={{
          width: '100%',
          position: 'relative',
          backgroundColor: 'input', 
          borderRadius: ['default'],

          boxShadow: 'default', 
          overflow: 'hidden',
          border: isError && '1px solid',
          borderColor: isError? 'red' : '#e2e2e2dd',

          '& input': {
            border: 'none',
            outline: 'none',
            paddingRight: getPadding(hasEndIcon),
            paddingLeft: getPadding(hasStartIcon),
            paddingTop: label? '0px' : '',
            borderRadius: '0px'
          },
          
          paddingLeft: getPadding(hasStartIcon),
          paddingRight: getPadding(hasEndIcon),
          ...sx
        }}
        {...props}
      >
        <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
          {
            label && 
              <Text sx={{ fontSize: [0], color: 'gray.7' }}>
                {label}
              </Text>
          }
          </Box>
          <Box>
            {option}
          </Box>
        </Flex>
        <Box mt={0}>
          {startIcon && <InputIcon>{startIcon}</InputIcon>}
          {cloneElement(children, {})}
          {endIcon && (
            <InputIcon isEndIcon>
              {endIcon}
            </InputIcon>
          )}
        </Box>
      </Box>
  )  
}

